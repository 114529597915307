const navigateToConf = (form) => {
  const parseParam = (param) => {
    console.log('param', param, ' value', form[param]);
    if (form[param] && (!(form.defaults && form.defaults[param]) || form.defaults[param] !== form[param])) {
      return form[param];
    } else {
      return '-1';
    }
  };

  const parseParamConstruction = (param) => {
    if (form[param] && (!(form.defaults && form.defaults[param]) || form.defaults[param] !== form[param])) {
      return form[param];
    } else {
      return '1';
    }
  };

  const parseParamHeight = (param) => {
    if (form[param] && form.dimensionHeightDefault !== form[param] && !form.heightDimensionWarning) {
      return form[param];
    } else {
      return '-1';
    }
  };

  const parseParamWidth = (param) => {
    if (form[param] && form.dimensionWidthDefault !== form[param] && !form.widthDimensionWarning) {
      return form[param];
    } else {
      return '-1';
    }
  };

  //zapiše v url tudi če je enak default vrednosti
  const parseAlwaysParam = (param) => {
    if (form[param]) {
      return form[param];
    } else {
      return '-1';
    }
  };

  const parseHandleColorsParam = (param) => {
    if (form[param] && form[param].length > 0) {
      return form[param];
    } else {
      return '-1';
    }
  };

  const parseColors = (param) => {
    if (param && Array.isArray(form[param])) {
      return form[param]
        .map((e) => {
          const colorId = e;

          //TODO defaults
          //if (colorId && (!(form.defaults && form.defaults[param]) || form.defaults[param] !== colorId)) {
          if (colorId) {
            return colorId;
          } else {
            return '-1';
          }
        })
        .toString();
    }
    return '[]';
  };

  const parseParamPosition = (param) => {
    if (param && Array.isArray(form[param])) {
      return form[param];
    } else {
      return '-1';
    }
  };

  const parseModelId = (param) => {
    if (form[param]) {
      return form[param];
    } else {
      if (window.inoAPI) {
        return window.inoAPI.getSestav().getModelId();
      }
    }
  };

  const parseModelName = (param) => {
    if (form[param] && !(form.defaults && form.defaults[param])) {
      return form[param];
    } else if (form[param] && form.defaults[param] !== form[param]) {
      return form.defaults[param];
    } else {
      if (window.inoAPI) {
        return window.inoAPI.getSestav().getModelName();
      }
    }
  };

  return (
    parseModelId('modelId') +
    '/' +
    parseModelName('modelName') +
    '/' +
    parseParam('doorOpeningLeftSide') +
    '/' +
    parseParam('doorOpeningRightSide') +
    '/' +
    parseParamConstruction('constructionType') +
    '/' +
    parseParamHeight('dimensionHeight') +
    '/' +
    parseParamWidth('dimensionWidth') +
    '/' +
    parseColors('colorWingOutsideId') +
    '/' +
    parseColors('colorFrameOutsideId') +
    '/' +
    parseColors('colorAplikacijeOutsideId') +
    '/' +
    parseColors('colorDekorOutsideId') +
    '/' +
    parseColors('colorOkvirckiOutsideId') +
    '/' +
    parseColors('colorWingInsideId') +
    '/' +
    parseColors('colorFrameInsideId') +
    '/' +
    parseColors('colorAplikacijeInsideId') +
    '/' +
    parseColors('colorDekorInsideId') +
    '/' +
    parseColors('colorOkvirckiInsideId') +
    '/' +
    parseAlwaysParam('motivType') +
    '/' +
    parseAlwaysParam('glassType') +
    '/' +
    parseParam('outsideHandle') +
    '/' +
    parseHandleColorsParam('outsideHandleColorsId') +
    '/' +
    parseParam('insideHandle') +
    '/' +
    parseParam('lockingType') +
    '/' +
    parseParam('dripBatten') +
    '/' +
    parseParam('houseNumberColId') +
    '/' +
    parseParam('houseNumber') +
    '/' +
    parseParam('peephole') +
    '/' +
    parseParam('knocker') +
    '/' +
    parseParamPosition('houseNumberPosition') +
    '/' +
    parseParamPosition('peepholePosition') +
    '/' +
    parseParamPosition('knockerPosition') +
    '/' +
    parseParam('miniCokel') +
    '/' +
    parseParam('blackEditionState')
  );
};

export default navigateToConf;
