import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Context } from 'shared-home/src/home/Context';
import { doorData } from './doorData';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import navigateToConf from './KonfiguratorURLParams';
import Uploady, { useItemProgressListener, useItemStartListener } from '@rpldy/uploady';
import UploadButton from '@rpldy/upload-button';
import UploadPreview from '@rpldy/upload-preview';
import styled from 'styled-components';
import { Circle } from 'rc-progress';

const StyledCircle = styled(Circle)`
  display: flex;
  margin: auto;
  width: 80px;
  height: 80px;
  margin-top: 20px;
`;

const VisualItemProgress = () => {
  const [progress, setProgress] = useState(0);

  useItemStartListener(() => {
    setProgress(() => 1);
  });

  useItemProgressListener((item) => {
    setProgress(() => item.completed);
  });

  return (
    //DISAPPEAR ON 100% AND DONT SHOW BEFORE UPLOAD STARTS
    progress !== 100 &&
    progress !== 0 && (
      <StyledCircle
        strokeWidth={8}
        gapPosition="top"
        gapDegree={20}
        trailColor="rgb(175, 180, 176)"
        strokeColor={{
          '0%': 'rgba(31,104,132,0.95)',
          '100%': 'rgba(2,32,53,1)'
        }}
        percent={progress}
      />
    )
  );
};

const filterBySize = (file) => {
  // 20MB
  return file.size <= 20000000;
};

const PreviewContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin: 5px;
    max-width: 400px;
    height: auto;
  }
`;

export default function PrototipVrat() {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const { form, onComponentChange, doorSvg, setDoorSvg, catalogue } = useContext(Context);

  const [fullscreenActive, setFullscreenActive] = useState(false);

  //SHOW MODALS - POPUPS
  const [doorBackground, setDoorBackground] = useState(false);
  const [doorPicture, setDoorPicture] = useState(false);

  // ICON IMAGES FOR ACTIVE CLASSES
  const [doorBackgroundIMG, setDoorBackgroundIMG] = useState(false);
  const [doorPictureIMG, setDoorPictureIMG] = useState(false);

  const [backgroundIndex, setBackgroundIndex] = useState();
  const [backgroundColor, setBackgroundColor] = useState();

  const [uploadFasadaId, setUploadFasadaId] = useState();
  const [backgroundImage, setBackgroundImage] = useState();

  // UPLOAD IMAGE PREVIEW
  const [uploadyPreview, setUploadyPreview] = useState(false);

  let uniqueTime = 0;

  useEffect(() => {
    {
      navigate('/configurator/' + navigateToConf(form));
    }
  }, [form]);

  //MOBILE CHECK
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    rerenderDoor();
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [form, fullscreenActive]);

  const isMobile = width <= 768 || height <= 612; // TRUE/FALSE

  function rerenderDoor() {
    if (window.inoAPI) {
      const inside = form.doorViewInside;
      const outside = form.doorViewOutside;
      if (inside) {
        setDoorSvg(window.inoAPI.getDoorSketcher().getSVGNotranji());
      } else {
        setDoorSvg(window.inoAPI.getDoorSketcher().getSVG());
      }
    }
  }

  const selectedBackgroundColor = (item, idx) => {
    setBackgroundColor(item);
    setBackgroundIndex(idx);
  };

  const selectedBackgroundPicture = (item) => {
    setBackgroundImage(item);
    setUploadyPreview(false);
  };

  const onCancelClick = () => {
    setDoorBackground(false);
    setDoorPicture(false);

    if (backgroundImage !== form.BGPicture) {
      setBackgroundImage(form.BGPicture);
    }

    if (backgroundColor !== form.BGColor) {
      setBackgroundColor(form.BGColor);
    }
  };

  const onViewChange = (e) => {
    if (e.target.name === 'doorViewInside' && form.doorViewInside !== true) {
      onComponentChange({ doorViewInside: true, doorViewOutside: false });
    }

    if (e.target.name === 'doorViewOutside' && form.doorViewOutside !== true) {
      onComponentChange({ doorViewInside: false, doorViewOutside: true });
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    //IF USER UPLOADS HIS OWN IMAGE
    if (uploadyPreview) {
      uniqueTime = Date.now();

      onComponentChange({ bgPictureFasadaId: uploadFasadaId });
      onComponentChange({ bgPictureMilis: uniqueTime });
      // onComponentChange({ bgPictureURL: window.InoAPI().getFasada().getFasadaImage()})

      // catalogue.defaultFasadeList[0] = { ...catalogue.defaultFasadeList[0], url: bgPictureURL };

      //MODAL CLOSE
      setDoorPicture(false);

      //NEEDED FOR PDF DOWNLOAD ON INQUIRY
      onComponentChange({ pozicioniranaFasada: true });
    }

    //IF USER PICKS ONE OF THE DEFAULT - STATIC IMAGES
    if (backgroundImage !== undefined) {
      setDoorPicture(false);
      onComponentChange({ BGColor: undefined });
      onComponentChange({ BGPicture: backgroundImage });

      //FOR PDF DOWNLOAD
      onComponentChange({ pozicioniranaFasada: true });
    }

    //SETS "ACTIVE" CLASSNAME
    setDoorBackgroundIMG(false);
    setDoorPictureIMG(true);
  };

  const onConfirm = () => {
    if (backgroundColor !== undefined) {
      //CLOSES MODAL GUI (POPUP)
      setDoorBackground(false);

      //SETS PICTURE BACKGROUND TO UNDEFINED - BOTH CANNOT BE ACTIVE AT THE SAME TIME
      onComponentChange({ BGPicture: undefined });

      onComponentChange({ pozicioniranaFasada: false });
      onComponentChange({ BGColor: backgroundColor });
      onComponentChange({ backgroundColorIndex: backgroundIndex });

      //SETS "ACTIVE" CLASSNAME
      setDoorBackgroundIMG(true);
      setDoorPictureIMG(false);
    }
  };

  useEffect(() => {
    if (window.inoAPI) {
      setUploadFasadaId(window.inoAPI.getFasada().getId());
    }
  }, [window.inoAPI]);

  const classChange = () => {
    setUploadyPreview(!uploadyPreview);

    if (backgroundImage) {
      setBackgroundImage(undefined);
    }
  };

  if (form.BGColor && backgroundColor === undefined) {
    setBackgroundColor(form.BGColor);
  }

  const fullscreenChange = () => {
    var elem = document.getElementById('door-window');
    if (document.fullscreenElement === null) {
      setFullscreenActive(true);

      if (elem.requestFullScreen) {
        elem.requestFullScreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullScreen) {
        elem.webkitRequestFullScreen();
      }
      // }
    } else {
      setFullscreenActive(false);
      document.exitFullscreen();
    }
  };

  useEffect(() => {
    if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
      setFullscreenActive(false);
    }
  }, [document.webkitIsFullScreen, document.mozFullScreen, document.msFullscreenElement]);

  let fullscreenSupported = document.fullscreenEnabled; //property tells whether full-screen can be enabled in the current page or not.

  let usingIphone = ['iPhone Simulator', 'iPhone'].includes(navigator.platform);

  return (
    <>
      {/* {!form.keyboardActive && */}
      <div id="door-window" className={'main-door show-door fixed-position background' + `${form.backgroundColorIndex}`}>
        {(fullscreenSupported || !usingIphone) && (
          <div className="wide-screen">
            <button className="wide-screen__icon cursor-pointer" onClick={() => fullscreenChange()}>
              <i className="ico ico-wide-screen">
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.5 1.375V0H0.6875C0.307785 0 0 0.307785 0 0.6875V5.5H1.375V2.34438L7.76188 8.73125L8.73125 7.76188L2.34438 1.375H5.5Z" fill="#666666" />
                  <path d="M21.3123 0H16.4998V1.375H19.6554L13.2686 7.76188L14.2379 8.73125L20.6248 2.34438V5.5H21.9998V0.6875C21.9998 0.307785 21.692 0 21.3123 0Z" fill="#666666" />
                  <path d="M20.6248 19.6556L14.2379 13.2687L13.2686 14.2381L19.6554 20.625H16.4998V22H21.3123C21.692 22 21.9998 21.6922 21.9998 21.3125V16.5H20.6248V19.6556Z" fill="#666666" />
                  <path d="M7.76188 13.2619L1.375 19.6557V16.5H0V21.3125C0 21.6922 0.307785 22 0.6875 22H5.5V20.625H2.34438L8.73125 14.2382L7.76188 13.2619Z" fill="#666666" />
                </svg>
              </i>
            </button>
          </div>
        )}
        <div className="selected-door" style={!fullscreenActive ? { paddingRight: 'inherit' } : {}}>
          <div id="svgContainer" className="svg-door-container" dangerouslySetInnerHTML={{ __html: doorSvg }}></div>
          {/* <i className="ico ico-door-bottom">
                      <svg width="634" height="29" viewBox="0 0 634 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M633.683 0.775391H0.0742188V28.812H633.683V0.775391Z" fill="url(#paint0_linear_1034_9045)" />

                            <g style={{mixBlendMode: 'multiply'}}>
                                <path d="M633.727 14.6602H0V17.1214H633.727V14.6602Z" fill="#BDBDBD"/>
                            </g>

                     <path d="M633.727 10.9683H0V14.6599H633.727V10.9683Z" fill="#F5F5F5" />
                            <defs>
                                <linearGradient id="paint0_linear_1034_9045" x1="316.884" y1="5.76124" x2="316.884" y2="41.9846" gradientUnits="userSpaceOnUse">
                                    <stop offset="0.01" stop-color="#E0E0E0" />
                                    <stop offset="0.35" stop-color="#DCDCDC" />
                                    <stop offset="0.68" stop-color="#D0D0D0" />
                                    <stop offset="1" stop-color="#BDBDBD" />
                                </linearGradient>
                            </defs>
                        </svg>
                        </i>
                    </div> */}
        </div>
        <div className="filters-for-selected-door">
          {!fullscreenActive && (
            <>
              <div className="filters-for-selected-door__view">
                <p className="filters-for-selected-door__title small upper-case">{t('confLook')}</p>
                <div className="filters-for-selected-door__filters">
                  <button name="doorViewOutside" onClick={onViewChange} className={'filters-for-selected-door__filter flex-line justify-center ' + (!form.doorViewInside && 'filters-for-selected-door__filter--active')}>
                    <i className="ico ico-outside" style={{ pointerEvents: 'none' }}></i>
                  </button>
                  <button name="doorViewInside" onClick={onViewChange} className={'filters-for-selected-door__filter flex-line justify-center ' + (form.doorViewInside && 'filters-for-selected-door__filter--active')}>
                    <i className="ico ico-inside" style={{ pointerEvents: 'none' }}></i>
                  </button>
                </div>
              </div>
              <div className="filters-for-selected-door__bg">
                <p className="filters-for-selected-door__title small upper-case">{t('confBackground')}</p>
                <div className="filters-for-selected-door__filters">
                  {/* OZADJE BARVE */}
                  <button name="facadeColor" onClick={() => setDoorBackground(true)} className={'filters-for-selected-door__filter flex-line justify-center ' + (doorBackgroundIMG && 'filters-for-selected-door__filter--active')}>
                    <i className="ico ico-fasada"></i>
                    {/* DINAMIČNO SPREMINJANJE BARVE IKONE - KVADRAT */}
                    {/* <img
                                            src={require('../assets/images/colors-wall/' + (backgroundColor && backgroundColor.name ? backgroundColor.name : 'white') + '.jpg')}
                                            className="ico ico-bg-gray"
                                        /> */}
                  </button>
                  <Modal backdrop="static" centered show={doorBackground} onHide={onCancelClick}>
                    <div className="popup popup-wall-color">
                      <div className="popup__scroller popup-wall-color__thin">
                        <div className="innerpopup">
                          <button className="innerpopup__close">
                            <i className="ico ico-close" onClick={() => onCancelClick()} />
                          </button>
                          <h1 className="upper-case">{t('BGColor')}</h1>
                          <ul className="innerpopup__colors no-bullets">
                            {doorData.barveOzadja.map((item, idx) => (
                              <li key={idx} className={'innerpopup__colors__single ' + (backgroundColor && backgroundColor.name === item.name && 'active')}>
                                <div className="cursor-pointer">
                                  <img src={require('../assets/images/colors-wall/' + item.name + '.jpg')} onClick={() => selectedBackgroundColor(item, idx)} style={{ cursor: 'pointer' }} alt={item.id} />
                                </div>
                              </li>
                            ))}
                          </ul>
                          {/* <p className="orange-font upper-case">Napredno izbiranje barve</p> */}
                          <div className="flex-line justify-center">
                            <button onClick={() => onConfirm()} className={'button-footer button-footer--orange upper-case ' + (!backgroundColor && 'button-footer--inactive')}>
                              <span>{t('SelectBGColor')}</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                  {/* OZADJE SLIKE */}
                  {/* <p className="filters-for-selected-door__title small upper-case">{t('confPicture')}</p> */}
                  {/* <button onClick={() => setDoorPicture(true)} className={"filters-for-selected-door__filter flex-line justify-center " + (doorPictureIMG && "filters-for-selected-door__filter--active")}>
                                        <i className="ico ico-frame"></i>
                                    </button> */}
                  <Modal backdrop="static" centered show={doorPicture} onHide={onCancelClick}>
                    <div className="popup popup-wall-image">
                      <div className="popup__scroller popup-wall-image__thin">
                        <div className="innerpopup">
                          <button className="innerpopup__close">
                            <i className="ico ico-close cursor-pointer" onClick={() => onCancelClick()}></i>
                          </button>
                          <h1 className="upper-case">{t('BGPictureTitle')}</h1>
                          <p className="">{t('BGPictureDesc')}</p>
                          <div className="innerpopup__steps">
                            <div className="innerpopup__steps__single">
                              <i className="ico ico-upload"></i>
                              <p className="grey-font xSmall">{t('BGPicturestep1')}</p>
                              <h3 className="upper-case">{t('BGPictureTip1')}</h3>
                            </div>
                            <i className="ico ico-next"></i>
                            <div className="innerpopup__steps__single">
                              <i className="ico ico-position"></i>
                              <p className="grey-font xSmall">{t('BGPicturestep2')}</p>
                              <h3 className="upper-case">{t('BGPictureTip2')}</h3>
                            </div>
                            <i className="ico ico-next"></i>
                            <div className="innerpopup__steps__single">
                              <i className="ico ico-config"></i>
                              <p className="grey-font xSmall">{t('BGPicturestep3')}</p>
                              <h3 className="upper-case">{t('BGPictureTip3')}</h3>
                            </div>
                          </div>
                          <div className="flex-line justify-center">
                            <Uploady accept=".gif, .jpg, .jpeg, .png, .tiff, .tif, .bmp" multiple={false} method="POST" destination={{ url: `./fasadaUpload?id=${uploadFasadaId}` }} fileFilter={filterBySize}>
                              <div>
                                <UploadButton className="button-footer button-footer--orange-border upper-case">{t('BGPictureUpload')}</UploadButton>
                                <VisualItemProgress />
                                <PreviewContainer onClick={() => classChange()} className={'innerpopup__images__single ' + (uploadyPreview && 'active')}>
                                  <UploadPreview />
                                </PreviewContainer>
                              </div>
                            </Uploady>
                          </div>
                          <br />
                          <p>{t('BGPictureDesc2')}</p>
                          <ul className="innerpopup__images no-bullets" style={{ display: 'grid', gridTemplateColumns: '5 1fr' }}>
                            {
                              // FIRST ID IS RESERVED FOR CUSTOM IMAGE - USER UPLOAD
                              catalogue.defaultFasadeList &&
                                catalogue.defaultFasadeList.slice(1).map((item, idx) => (
                                  <li key={idx} className={'innerpopup__images__single ' + (backgroundImage && backgroundImage.id === item.id && 'active')}>
                                    <div key={idx} className="cursor-pointer">
                                      <img src={'/konfigurator/background-images/' + item.url_thumb} onClick={() => selectedBackgroundPicture(item)} alt={item.id} />
                                    </div>
                                  </li>
                                ))
                            }
                          </ul>
                          <div className="flex-line justify-center">
                            <button onClick={handleUpload} className={'button-footer button-footer--orange upper-case ' + (!(backgroundImage || uploadyPreview) && 'button-footer--inactive')} href="#">
                              <span>{t('BGPictureChoose')}</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
            </>
          )}

          {fullscreenActive && !isMobile && (
            <div className="filters-for-selected-door__view">
              <p className="filters-for-selected-door__title small upper-case">{t('confLook')}</p>
              <div className="filters-for-selected-door__filters">
                <button name="doorViewOutside" onClick={onViewChange} className={'filters-for-selected-door__filter  flex-line justify-center ' + (form.doorViewOutside === true && 'filters-for-selected-door__filter--active')}>
                  <i className="ico ico-outside" style={{ pointerEvents: 'none' }}></i>
                </button>
                <button name="doorViewInside" onClick={onViewChange} className={'filters-for-selected-door__filter  flex-line justify-center ' + (form.doorViewInside === true && 'filters-for-selected-door__filter--active')}>
                  <i className="ico ico-inside" style={{ pointerEvents: 'none' }}></i>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* } */}
    </>
  );
}
