import setWith from 'lodash/setWith';

export function setObjectDeepValue(obj, path, value) {
    // This will shallow clone only the objects in the path that changed
    const objectCopy = shallowObject(obj);
    setWith(objectCopy, path, value, shallowObject);
    return objectCopy;
}

function shallowObject(obj) {
    return {...obj};
}

export const overrideTooltipPosition = (
  {left, top},
  currentEvent, currentTarget, node) => {
    const d = document.documentElement;
    left = Math.min(d.clientWidth - node.clientWidth, left);
    top = Math.min(d.clientHeight - node.clientHeight, top);
    left = Math.max(0, left);
    top = Math.max(0, top);
    const targetLeft = currentTarget.x;
    const targetTop = currentTarget.y;
    const targetBottom = targetTop + currentTarget.offsetHeight;
    if (targetBottom > top) {
        top = top + currentTarget.offsetHeight + 5;
        left = d.clientWidth - node.clientWidth - 30;
    }
    return {top, left}
};