export const translationPl = {
  modelType: "Model",
  chooseModel: "Programy aluminiowych drzwi wejściowych",
  glass: "Szkło",
  modelShape: "Forma szyby",
  SearchApp: "Aplikacje na modelu",
  chosenModel: "Wybrany model",
  configureDoors: "Skonfiguruj Twoje drzwi",
  allDoors: "Wszystkie",
  findModel: "Szukaj modelu",
  showResults: "Pokaż rezultat",
  filter: "Filtruj",
  save: "Zapisz by kontynuować później",
  sendInquiry: "Wyślij zapytanie",
  headerModel: "Wybierz model",
  configurator: "Konfigurator",
  confDoorOpening: "Kierunek DIN",
  confLeft: "Lewy",
  confRight: "Prawy",
  confConstructionType: "Typ i konstrukcja",
  confDimensions: "Wymiary",
  confHeight: "Wysokość",
  confWidth: "Szerokość",
  confHeightExample: "Np. 2100 mm",
  confWidthExample: "Np.1050 mm",
  confExample: "Np.",
  confSkipDimensions: "Jeśli w tej chwili nie posiadasz informacji dotyczących wymiarów, możesz pominąć ten krok.",
  confColors: "Kolory",
  confColWhole: "POKOLORUJ WSZYSTKIE ELEMENTY",
  confColAdvanced: "Rozszerzony wybór kolorów",
  confCol1: "Kolor 1",
  confCol2: "Kolor 2",
  confMoreColors: "Pokaż więcej kolorów",
  confColorTop: "Top 10 najczęściej sprzedawanych kolorów",
  confFindColor: "Znajdź Kolor",
  confColorGroup: "GRÓPA KOLORÓW",
  confColorShade: "Odcień",
  confColMa: "Matowy",
  confColSt: "Delikatna struktura",
  confColShine: "Połysk",
  confColLess: "Ukryj kolory",
  confColReset: "Wróć",
  confColWing: "Skrzydło",
  confColOutside: "Zewnątrz",
  confColInside: "Wewnątrz",
  confColFrame: "Rama drzwi",
  confGlass: "Szyba",
  confMotGlass: "MOTYW DESIGN",
  confOrnGlass: "SZYBA ORNAMENTOWA",
  confHandle: "Pochwyty",
  confHandleOutside: "POCHWYT zewnętrzny",
  confHandleInside: "Klamka wewnątrz",
  confHandleDesc: "Opis",
  confHandleDim: "Wymiary",
  confLock: "Zamek",
  confLockTop: "Najpopularniejsze systemy zamknięcia",
  confLockTypes: "Pokaż więcej wariantów",
  confLockAll: "Wszystkie systemy zamknięcia.",
  confLockLess: "Ukryj system zamknięcia",
  confExtras: "Akcesoria",
  confExtrasBattens: "Okapnik",
  confSummary: "Podsumowanie konfiguracji",
  confContinue: "Kontynuuj później",
  confLook: "Widok",
  confBackground: "Tło",
  confPicture: "Zdjęcie",
  confChooseModel: "Wybór modelu",
  confPickedModel: "Wybrany model",
  confSwitchModel: "Zmień model",
  confHouseNo: "Numer domu",
  confPeephole: "Wizjery",
  confKnocker: "Kołatka",
  confHouseInput: "Wpisz numer domu",
  confAdjust: "Dostosuj",
  confData: "Twoje dane",
  InqName: "Imię",
  InqSurname: "Nazwisko",
  InqAddress: "Ulica, Nr.",
  InqPost: "Kod pocztowy",
  InqCity: "Miejscowość",
  InqEmail: "E-mail",
  InqEmDesc: "Potrzebujemy Twojego adresu e-mail, aby przesłać Ci niezobowiązującą ofertę na drzwi wejściowe.",
  InqPhone: "Telefon",
  InqPhoneDesc: "Potrzebujemy Twojego numeru telefonu jedynie w przypadku, gdy będziemy potrzebować dodatkowych informacji w celu przygotowania oferty.",
  InqMessage: "Czy chciałby Pan/Pani, przekazać dodatkowe informacje?",
  InqDoor: "Czy chodzi o remont czy budowę nowego obiektu?",
  InqMark: "Proszę odpowiednio określić",
  InqNew: "Nowy obiekt",
  InqChange: "Renowacja",
  InqContact: "Kontakt w celu uzyskania porady, sprzedaży i montażu.",
  InqAgreement: "Zgadzam się, że moje dane kontaktowe zostaną przekazane sprzedawcy lub upoważnionemu partnerowi w celu udzielenia porady i przygotowania oferty.",
  InqNews: "Darmowe i niezobowiązujące przesłanie prospektów.",
  InqFinish: "Wyślij zapytanie",
  InqRequired: "Pole obowiązkowe",
  InqSummary: "Podsumowanie konfiguracji",
  InqInvalidEMail: "Nieprawidłowy adres e-mail",
  InqConfSummary: "Lista pełnej konfiguracji",
  InqPDF: "Pobierz PDF",
  InqShare: "Udostępnij link",
  InqVisit: "Zapraszamy do odwiedzenia naszego salonu wystawowego",
  InqModel: "Model",
  InqColOutside: "Zewnątrz",
  InqColInside: "Wewnątrz",
  InqLeverInside: "Klamka wewnątrz",
  InqLeverOutside: "Pochwyt zewnętrzny",
  InqHouseNo: "Numer domu",
  InqDripBattens: "Okapnik",
  InqKnocker: "Kołatka",
  InqPeephole: "Wizjery",
  close: "Zamknięcie",
  classicType: "Klasyczny",
  modernType: "Nowoczesny",
  extravagantType: "Ekstrawagancki",
  simpleType: "Prosty",
  topSeller: "Lider sprzedaży",
  glassNone: "Bez",
  glassSmall: "Mały",
  glassMedium: "Średni",
  glassBig: "Duży",
  shapeRectangle: "Prostokątny",
  shapeSquare: "Kwadratowy",
  shapeCurves: "Krzywe i łuki",
  shapeOther: "Inne formy",
  searchMulticolor: "Wielokolorowy",
  searchInox: "Aplikacje stal szlachetna",
  searchUtori: "Relief",
  BGColor: "Wybierz kolor tła",
  SelectBGColor: "Wybierz kolor",
  BGPictureTitle: "Wstaw zdjęcie",
  BGPictureDesc: "Dodaj zdjęcie swojego domu lub wybierz obraz spośród następujących zdjęć.",
  BGPicturestep1: "Krok 1",
  BGPictureTip1: "Prześlij zdjęcie lub wybierz z dostępnych opcji",
  BGPicturestep2: "Krok 2",
  BGPictureTip2: "Ustaw drzwi w odpowiedniej pozycji",
  BGPicturestep3: "Krok 3",
  BGPictureTip3: "Kontynuuj konfigurację",
  BGPictureUpload: "Prześlij zdjęcie z komputera",
  BGPictureDesc2: "lub wybierz jedno z poniższych",
  BGPictureChoose: "Użyj zdjęcia",
  confOpening: "Kierunek otwarcie DIN",
  confThankYouPage: "Dziękujemy za zapytanie!",
  noModelResults: "Brak wyników",
  confAppOut: "Aplikacje na zewnątrz",
  confAppIn: "Aplikacje od wewnątrz",
  confDekorOut: "Dekor na zewnątrz",
  confDekorIn: "Dekor od wewnątrz",
  confWindowsOut: "Aluminiowa rama ozdobna na zewnątrz",
  confWindowsIn: "Aluminiowa rama ozdobna od wewnątrz",
  confHandleOutsideMainColor: "Wybrany kolor",
  CustomerInformation: "Dodatkowe dane",
  CustomerContact: "Dane kontaktowe",
  CustomerNameSurname: "Imię i nazwisko",
  CustomerAddress: "Ulica i numer domu",
  CustomerPostCity: "Kod pocztowy i miejscowość",
  CustomerCountry: "Kraj",
  CustomerEmail: "E-mail",
  CustomerPhone: "Numer telefonu",
  CustomerNote: "Dodatkowe życzenia",
  CustomerDoorSetup: "Czy drzwi są przeznaczone do remontu czy do nowego budynku?",
  TyPageDownload: "Przekształć konfigurację w plik PDF",
  TyPageShare: "Udostępnij link do konfiguracji",
  TyPageAppreciation: "Cieszymy się i jesteśmy bardzo zaszczyceni, że zainteresowali się Państwo zakupem drzwi wejściowych marki Inotherm. Jesteśmy przekonani, że drzwi te doskonale pasują do Państwa domu. Jeśli zdecydują się Państwo na ich zakup, dołączą Państwo do tysięcy zadowolonych klientów na całym świecie, których domy już wyposażone są w drzwi Inotherm.",
  TyPageHeader: "Dziękujemy za zapytanie!",
  TyPageDescription1: "W powyższej tabeli znajdują się wszystkie szczegóły dotyczące Twojej aktualnej konfiguracji. W ciągu najbliższych 24 godzin otrzymasz również niezobowiązującą ofertę. Dziękujemy!",
  TyPageDescription2: "Jeden z naszych pracowników skontaktuje się wkrótce w celu omówienia kolejnych kroków, takich jak zmiany w konfiguracji, wymiary otworu ściany i finalne zamówienie. Dziękujemy!",
  model: "Model",
  confHandleOutsideExtraColoring: "Dodatkowe kolory",
  confHandleOutsideColor: "Kolor pochwytu",
  confHandleOutsideAdditionalColor: "Kolor zagłębionej części",
  confColorsOutside: "Kolor zewnątrz",
  confColorsInside: "Kolor wewnątrz",
  TyPageCopyRights: "All rights reserved 2023",
  logoLink: "https://www.inotherm.com/",
  InoWebsite: "Do naszej strony",
  positionHouseNum: "Pozycja numeru domu na drzwiach",
  positionKnocker: "Pozycja kołatki na drzwiach",
  positionPeephole: "Pozycja wizjera na drzwiach",
  positionInfo: "Proszę użyć kursora, aby umieścić część akcesoriów we właściwym miejscu.",
  positionHorizontal: "POZIMO",
  positionVertical: "PIONOWO",
  headerFilterVsi: "Wszystkie modele",
  headerFilterAkcija24: "Trend 24",
  headerFilterZimska: "Zimowa promocja",
  headerFilterTop: "Best sellers",
  headerFilterAkcija: "Black Edition",
  openFilters: "FILTRY",
  clearFilters: "Wyczyść wszystko",
  useFilters: "Pokaż wyniki",
  bannerText: "WYBIERZ MODEL DRZWI I ROZPOCZNIJ PROJEKTOWANIE SWOJEGO WEJŚCIA",
  miniCokel: "Niska listwa dolna",
  monthlyTopModel: "Drzwi miesiąca",
  blackEditionTitle: "Black Edition",
  blackEditionDesc: "Podnieś projekt swoich wymarzonych drzwi z ponadczasowością czerni.",
  blackEditionAdd: "DODAĆ",
  blackEditionRemove: "USUNĄĆ",
}
