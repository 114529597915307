export const translationFr = {
	modelType: "Modèle",
	chooseModel: "Gammes de portes",
	glass: "Vitrage",
	modelShape: "Forme de vitrage",
	SearchApp: "Finition optionnelle",
	chosenModel: "Votre modèle",
	configureDoors: "Configurez votre porte",
	allDoors: "Toutes les portes",
	findModel: "Recherche de modèle",
	showResults: "Résultats",
	filter: "Filtre",
	save: "Enregistrez et continuez plus tard",
	sendInquiry: "Étape suivante",
	headerModel: "Choisissez le modèle",
	configurator: "Configurateur",
	confDoorOpening: "Sens d'ouverture",
	confLeft: "Gauche",
	confRight: "Droite",
	confConstructionType: "FORMES",
	confDimensions: "Dimensions",
	confHeight: "Hauteur",
	confWidth: "Largeur",
	confHeightExample: "p. ex. 2100 mm",
	confWidthExample: "p. ex. 1050 mm",
	confExample: "p. ex.",
	confSkipDimensions: "Si vous ne disposez pas actuellement d'informations sur les dimensions, vous pouvez ignorer cette étape.",
	confColors: "Couleurs",
	confColWhole: "Teinte",
	confColAdvanced: "Plus de couleurs avancées",
	confCol1: "Couleur 1",
	confCol2: "Couleur 2",
	confMoreColors: "Afficher plus de portes",
	confColorTop: "Nos 10 couleurs les plus vendues",
	confFindColor: "Trouvez votre couleur",
	confColorGroup: "GROUPE DE COULEURS",
	confColorShade: "Nuance",
	confColMa: "Mat",
	confColSt: "Structuré",
	confColShine: "Brillante",
	confColLess: "Masquer les couleurs",
	confColReset: "Réinitialisation",
	confColWing: "Battant",
	confColOutside: "Extérieur",
	confColInside: "Intérieur",
	confColFrame: "Cadre de la porte",
	confGlass: "Vitrages",
	confMotGlass: "MOTIF SUR VITRAGE",
	confOrnGlass: "VERRE D'ORNEMENT",
	confHandle: "BARRE DE TIRAGE",
	confHandleOutside: "POIGNÉE DE TIRAGE",
	confHandleInside: "Poignée intérieure",
	confHandleDesc: "Description",
	confHandleDim: "Dimensions",
	confLock: "Verrouillage",
	confLockTop: "Les systèmes de verrouillage les plus utilisés",
	confLockTypes: "Afficher plus de systèmes de verrouillage",
	confLockAll: "Tous les systèmes de verrouillage",
	confLockLess: "Masquer les systèmes de verrouillage",
	confExtras: "Accessoires",
	confExtrasBattens: "Plinthes décoratives",
	confSummary: "Sommaire",
	confContinue: "Continuez plus tard",
	confLook: "Vue",
	confBackground: "Façade ",
	confPicture: "Photo",
	confChooseModel: "Choisissez le modèle",
	confPickedModel: "Votre sélection de porte",
	confSwitchModel: "Changez de modèle",
	confHouseNo: "Numéro de maison",
	confPeephole: "Judas en laiton",
	confKnocker: "Heurtoir",
	confHouseInput: "Entrez votre numéro de maison",
	confAdjust: "Modifier",
	confData: "Vos coordonnes",
	InqName: "Prénom",
	InqSurname: "Nom",
	InqAddress: "Adresse",
	InqPost: "Code postal",
	InqCity: "Ville",
	InqEmail: "E-mail",
	InqEmDesc: "Nous avons besoin de votre adresse e-mail afin de vous envoyer votre devis.",
	InqPhone: "Téléphone",
	InqPhoneDesc: "Nous avons besoin de votre numéro téléphone pour toutes informations supplémentaires",
	InqMessage: "Avez-vous des questions ?",
	InqDoor: "Votre projet concerne:",
	InqMark: "",
	InqNew: "En construction",
	InqChange: "En rénovation",
	InqContact: "Je souhaite être contacté pour recevoir mon devis ou pour l'installation de ma porte.",
	InqAgreement: "J'accepte que mes coordonnées soient transmises aux vendeurs ou partenaires autorisés dans le cadre de mon devis.",
	InqNews: "Je souhaite recevoir un catalogue gratuit.",
	InqFinish: "Envoyer ma demande de devis",
	InqRequired: "Champs requis",
	InqSummary: "Votre configuration de porte",
	InqInvalidEMail: "E-mail invalide",
	InqConfSummary: "Voir le détail de ma configuration",
	InqPDF: "Exporter au format PDF",
	InqShare: "Partagez le lien",
	InqVisit: "Visitez notre showroom",
	InqModel: "Modèle",
	InqColOutside: "Extérieur",
	InqColInside: "Intérieur",
	InqLeverInside: "Poignée intérieure",
	InqLeverOutside: "Barre de tirage extérieure",
	InqHouseNo: "Numéro de maison",
	InqDripBattens: "Plinthes décoratives",
	InqKnocker: "Heurtoir",
	InqPeephole: "Judas en laiton",
	close: "Fermer",
	classicType: "Traditionnel",
	modernType: "Moderne",
	extravagantType: "Extravagant",
	simpleType: "Classique",
	topSeller: "Les plus vendues",
	glassNone: "Sans vitrage",
	glassSmall: "Partiellement vitrée",
	glassMedium: "Semi-vitrée",
	glassBig: "Grand vitrage",
	shapeRectangle: "Rectangles",
	shapeSquare: "Carrés",
	shapeCurves: "Courbes et arcs",
	shapeOther: "Autres formes",
	searchMulticolor: "Bicoloration",
	searchInox: "Applications Inox",
	searchUtori: "Reliefs",
	BGColor: "Choisir la couleur de fond",
	SelectBGColor: "Choisir la couleur",
	BGPictureTitle: "Importer sa propre photo",
	BGPictureDesc: "Importer sa propre photo ou choisir une photo ci-dessous",
	BGPicturestep1: "Étape 1",
	BGPictureTip1: "Téléchargez ou choisissez la photo",
	BGPicturestep2: "Étape 2",
	BGPictureTip2: "Positionnez la porte",
	BGPicturestep3: "Étape 3",
	BGPictureTip3: "Continuez avec la configuration",
	BGPictureUpload: "Téléchargez la photo",
	BGPictureDesc2: "ou choisissez la photo ci-dessous",
	BGPictureChoose: "Utiliser la photo",
	confOpening: "Ouverture",
	confThankYouPage: "Nous vous remercions pour votre demande !",
	noModelResults: "Aucun resultat",
	confAppOut: "Application exterieure",
	confAppIn: "Application interieure",
	confDekorOut: "Décor exterieur",
	confDekorIn: "Décor interieur",
	confWindowsOut: "Moulure exterieure",
	confWindowsIn: "Moulure interieure",
	confHandleOutsideMainColor: "Votre couleur",
	CustomerInformation: "Vos coordonnées",
	CustomerContact: "Vos coordonnées",
	CustomerNameSurname: "Nom et prénom",
	CustomerAddress: "Rue et numéro",
	CustomerPostCity: "Numéro de maison",
	CustomerCountry: "Pays",
	CustomerEmail: "E-mail",
	CustomerPhone: "Numéro de téléphone",
	CustomerNote: "Remarques sur votre commande",
	CustomerDoorSetup: "Avez-vous besoin d'une porte pour une rénovation ou pour une construction?",
	TyPageDownload: "Télécharger la configuration dans un document pdf",
	TyPageShare: "Partager un lien vers la configuration",
	TyPageAppreciation: "Nous sommes heureux et honorés que vous soyez intéressés par l'achat de portes d'entrée Inotherm. Nous croyons qu'elles correspondront à votre façade. Vous rejoindriez les milliers d'utilisateurs satisfaits à travers le monde dont les maisons sont déjà décorées avec des portes d'entrée Inotherm.",
	TyPageHeader: "Merci pour votre demande !",
	TyPageDescription1: "Dans le tableau ci-dessus, vous pouvez voir tous les détails de votre configuration actuelle, pour laquelle vous recevrez également une offre sans engagement dans les prochaines 24 heures.",
	TyPageDescription2: "Notre commercial vous contactera bientôt. Il vous aidera aux étapes ultérieures, telles que les modifications de configuration, la mesure de l'ouverture du mur et la commande finale.",
	model: "Modèle",
	confHandleOutsideExtraColoring: "Couleurs supplémentaires",
	confHandleOutsideColor: "Couleur de barre de tirage",
	confHandleOutsideAdditionalColor: "Couleur de poignée concave",
	confColorsOutside: "Couleurs extérieures",
	confColorsInside: "Couleurs interieures",
	TyPageCopyRights: "Tous droits réservés 2022",
	logoLink: "https://www.inotherm.fr/",
	InoWebsite: "Sur notre site Web",
	positionHouseNum: "Position du numéro de maison sur la porte",
	positionKnocker: "Position du heurtoir sur la porte",
	positionPeephole: "Position du judas sur la porte",
	positionInfo: "Choisissez la position du numéro",
	positionHorizontal: "HORIZONTALEMENT",
	positionVertical: "VERTICALEMENT",
	headerFilterVsi: "Tous les modèles",
	headerFilterAkcija24: "Trend 24",
	headerFilterZimska: "Promotion d’hiver",
	headerFilterTop: "Best sellers",
	headerFilterAkcija: "Black Edition",
	openFilters: "FILTRES",
	clearFilters: "Effacer les filtres",
	useFilters: "Montrer les résultats",
	bannerText: "CHOISISSEZ UN MODÈLE DE PORTE D'ENTRÉE ET COMMENCEZ À CONCEVOIR VOTRE ENTRÉE",
	miniCokel: "Socle bas",
	monthlyTopModel: "Porte du mois",
	blackEditionTitle: "Black Edition",
	blackEditionDesc: "Sublimez le design de votre porte de rêve avec l’élégance intemporelle du noir.",
	blackEditionAdd: "AJOUTER",
	blackEditionRemove: "RETIRER",
}
