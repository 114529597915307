import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Context } from 'shared-home/src/home/Context';
import { getPartnerCodeEmbedded } from 'shared-home/src/home/PartnerCode';

function ThankYouPage() {
  const { t } = useTranslation();
  const { form, doorSvg, catalogue } = useContext(Context);
  const [summary, setSummary] = useState(false);
  const [doorViewInside, setDoorViewInside] = useState();
  const [doorViewOutside, setDoorViewOutside] = useState();
  const url = './images/';

  const partnerParameters = JSON.parse(window.inoAPI.getPartnerParameters()); //Scroll to top on page render
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //MOBILE CHECK
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768; // TRUE/FALSE

  const getDoorNeed = () => {
    if (form.customerSetupNewDoor) {
      // return 'Novogradnja';
      return `${t('InqNew')}`;
    } else if (form.customerSetupChangeDoor) {
      // return 'Montaža';
      return `${t('InqChange')}`;
    }
  };

  const onDownloadPDF = () => {
    window.dataLayer.push({
      event: 'export_to_pdf'
    });

    const loadingScreen = document.getElementById('loading-wait');
    loadingScreen.style.display = 'flex';
    console.log('start pdf');
    window.inoAPI.getOffer().printKonfigurator(form.pozicioniranaFasada ? form.pozicioniranaFasada : false, form.BGColor ? form.BGColor.id : '#EEEEEE', getPartnerCodeEmbedded(), form.configuratorURL, (success, data, error) => {
      loadingScreen.style.display = 'none';
      if (success) {
        var byteArray = new Uint8Array(data);
        const blob = new Blob([byteArray], { type: 'application/pdf;charset=utf-8' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', form.modelName + '_' + Date.now() + '.pdf');

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        if (link.parentNode) {
          // Clean up and remove the link
          link.parentNode.removeChild(link);
        }
        console.log('end pdf, success!');
      } else {
        window.alert('An internal error occurred. Please try again later.');
        console.log('end pdf, error!');
      }
    });
  };

  const onLinkShare = () => {
    window.dataLayer.push({
      event: 'configuration_share'
    });

    if (window.location.protocol === 'https:') {
      // SHOWS INSTALLED APPS WHERE LINK CAN BE SHARED ON MOBILE; ELSE COPIES LINK TO CLIPBOARD
      if (navigator.share) {
        navigator
          .share({
            url: form.configuratorURL
          })
          .then(() => console.log('Successful share'))
          .catch((error) => console.log('Error sharing:', error));
      } else {
        navigator.clipboard.writeText(form.configuratorURL);
        window.alert('URL Copied to clipboard');
      }
    } else {
      window.alert('Copy link in the URL');
    }
  };

  function getModel(cat, selected) {
    return (
      cat &&
      cat
        .filter(function (item) {
          return item.id === selected;
        })
        .map(function ({ model, previewPath, imagePath }) {
          return { model, previewPath, imagePath };
        })[0]
    );
  }

  const peepholeModel = getModel(catalogue.kukala, form.peephole);

  const knockerModel = getModel(catalogue.tolkala, form.knocker);

  const dripBattenModel = getModel(catalogue.odkapneLetve, form.dripBatten);

  const lockModel = getModel(catalogue.zaklepanja, form.lockingType);

  const insideHandleModel = getModel(catalogue.notrDrzala, form.insideHandle);

  const ornamentnaModel = getModel(catalogue.steklaOrn, form.glassType);

  const motivnaModel =
    catalogue.steklaMot &&
    catalogue.steklaMot
      .filter(function (item) {
        return item.id.id === form.motivType;
      })
      .map(function ({ id }) {
        return { id };
      });

  const houseNumberImage =
    catalogue.hisneStevilke &&
    catalogue.hisneStevilke
      .filter(function (item) {
        return item.id === form.houseNumberIMG;
      })
      .map(function ({ previewPath }) {
        return { previewPath };
      });

  let kljukaId = form.outsideHandle + 'id';
  const outsideHandle = catalogue.zunanjaDrzalaMap && catalogue.zunanjaDrzalaMap[kljukaId] && catalogue.zunanjaDrzalaMap[kljukaId][0];

  //GET INSIDE/OUTSIDE VIEW ON window.inoAPI / PAGE LOAD
  useEffect(() => {
    if (window.inoAPI) {
      setDoorViewInside(window.inoAPI.getDoorSketcher().getSVGCleanNotranji());
      setDoorViewOutside(window.inoAPI.getDoorSketcher().getSVGClean());
    }
  }, [window.inoAPI]);

  const ActiveColors = ({ colorArray, title }) => {
    return (
      <>
        {colorArray &&
          colorArray.map((value, index) => (
            <>
              <div className="thank-you-summary-frame-info">
                <img src={url + value.barva.slika} alt={index} className="thank-you-summary-frame-image" />
                <div className="thank-you-summary-frame-section">
                  <div className="thank-you-summary-frame-section-title">
                    <p className="thank-you-summary-frame-section-title-small">
                      {/* {t('InqColOutside')} */}
                      {title}
                    </p>
                  </div>
                  <div className="thank-you-summary-frame-section-title2">
                    <div className="thank-you-summary-frame-section-title3">
                      <p className="thank-you-summary-frame-section-title-big">{value.barva.name}</p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
      </>
    );
  };

  const goHomePage = () => {
    const getLink = window.inoAPI.getHomePageLink();

    if (getLink === null) {
      // navigate(`/${t('logoLink')}`, { replace: true })
      window.location.replace(`${t('logoLink')}`);
    } else {
      window.location.replace(`${getLink}`);
    }
  };

  return (
    <>
      <header className="thank-you-header">
        <div className="logo__image ino-logo cursor-pointer" onClick={goHomePage}></div>
      </header>
      <div id="loading-wait" className="loading-wait">
        <i className="ico ico-loading"></i>
      </div>
      <div className="thank-you-main-wrapper">
        <div className="thank-you-form-wrapper">
          {/* <div> */}

          {/* ZAHVALA ZA POVPRAŠEVANJE, DELI, PRENESI */}
          <div className="thank-you-customer-title">
            <h1 className="thank-you-customer-header">
              {form.customerName} {form.customerSurname}, {t('TyPageHeader')}
            </h1>
            <p className="thank-you-customer-body">{t('TyPageAppreciation', { companyInfo: partnerParameters && partnerParameters.companyInfoText ? partnerParameters.companyInfoText : '' })}</p>
            <div className="thank-you-customer-actions">
              <button className="thank-you-share" onClick={onLinkShare}>
                <div className="thank-you-share-image" style={{ textAlign: 'center' }}>
                  <svg width="31" height="20" className="svg-mobile" style={{ marginTop: '20%' }} viewBox="0 0 31 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1.1791 20.0013C1.07167 20.0008 0.965869 19.975 0.870341 19.9262C0.774813 19.8773 0.69226 19.8067 0.629425 19.7201C0.566591 19.6335 0.525256 19.5333 0.508787 19.4278C0.492317 19.3223
                    0.501184 19.2144 0.534663 19.1129C1.77669 15.371 4.14393 12.098 7.31684 9.7355C10.4898 7.37305 14.3156 6.03496 18.2779 5.90187V0.674872C18.278 0.545733 18.3155 0.419351 18.3857 0.310728C18.456
                    0.202104 18.5562 0.115801 18.6743 0.0620619C18.7925 0.00832238 18.9237 -0.0105959 19.0523 0.00755153C19.181 0.0256989 19.3016 0.0801493 19.4001 0.164442L30.2643 9.4896C30.3382 9.55283 30.3975
                    9.63117 30.4381 9.71926C30.4787 9.80735 30.4998 9.90312 30.4998 10C30.4998 10.0969 30.4787 10.1927 30.4381 10.2808C30.3975 10.3689 30.3382 10.4472 30.2643 10.5105L19.4001 19.8356C19.3011 19.919
                     19.1805 19.9729 19.0521 19.9911C18.9236 20.0093 18.7926 19.9912 18.6742 19.9387C18.5559 19.8849 18.4557 19.7984 18.3855 19.6897C18.3152 19.5809 18.2779 19.4544 18.2779 19.3252V14.119C12.2505
                     14.2751 6.41967 16.2824 1.58652 19.8651C1.46926 19.9533 1.32621 20.0012 1.1791 20.0013ZM18.9569 0.36935C18.8751 0.369673 18.7968 0.402096 18.739 0.459552C18.6812 0.517009 18.6486 0.594843 18.6482
                      0.676098V6.26015H18.468C14.5528 6.36597 10.7653 7.66694 7.61983 9.98635C4.4744 12.3058 2.12352 15.5312 0.886511 19.2246C0.865794 19.2865 0.865524 19.3535 0.885743 19.4156C0.905961 19.4778
                      0.945612 19.5319 0.998922 19.5701C1.05223 19.6083 1.11642 19.6286 1.18213 19.628C1.24785 19.6274 1.31166 19.606 1.36429 19.5669C6.31306 15.9008 12.2919 13.8654 18.4631 13.746H18.6519V19.3252C18.6517
                      19.3839 18.6686 19.4415 18.7006 19.4909C18.7326 19.5404 18.7783 19.5795 18.8322 19.6037C18.8859 19.6278 18.9455 19.6362 19.0038 19.6279C19.0622 19.6196 19.117 19.5951 19.1618 19.5571L30.026 10.2319C30.0597
                       10.2032 30.0867 10.1677 30.1052 10.1276C30.1237 10.0876 30.1333 10.0441 30.1333 10C30.1333 9.95597 30.1237 9.91244 30.1052 9.87241C30.0867 9.83238 30.0597 9.7968 30.026 9.76812L19.1618 0.44297C19.1051
                       0.393838 19.0321 0.36842 18.9569 0.36935ZM19.2655 18.6516V13.4356C19.2655 13.3543 19.233 13.2762 19.1751 13.2187C19.1172 13.1612 19.0387 13.1289 18.9569 13.1289C13.0366 13.1513 7.25247 14.8963 2.31861
                       18.1485L1.79762 18.4933L2.04454 17.9227C3.46745 14.6386 5.82811 11.8413 8.83434 9.87697C11.8406 7.91266 15.3604 6.86762 18.9581 6.87119C19.04 6.87119 19.1185 6.83887 19.1763 6.78135C19.2342 6.72382
                       19.2668 6.6458 19.2668 6.56444V1.34481L29.3482 10L19.2655 18.6516ZM18.9569 12.762C19.1369 12.7623 19.3094 12.8335 19.4366 12.96C19.5639 13.0865 19.6356 13.258 19.6359 13.4368V17.854L28.7816 10.0013L19.6359
                       2.14849V6.56567C19.6356 6.74455 19.5639 6.91601 19.4366 7.0425C19.3094 7.16899 19.1369 7.24019 18.9569 7.24052C15.539 7.23745 12.1906 8.20027 9.3024 10.0167C6.41417 11.8331 4.10511 14.4282 2.64453
                       17.4994C7.5249 14.4256 13.1791 12.7843 18.9569 12.762Z"
                    />
                  </svg>
                </div>
                <p className="thank-you-share-text">{t('TyPageShare')}</p>
              </button>
              <button className="thank-you-download" onClick={onDownloadPDF}>
                <div className="thank-you-share-image">
                  <svg width="25" height="32" className="svg-mobile" style={{ marginTop: '10%' }} viewBox="0 0 25 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.0713 0H0.5V29.85H11.9359L10.8777 28.5483H1.7749V1.30172H17.1693V7.15945C17.1693 7.33207 17.2365 7.49761 17.356 7.61967C17.4756 7.74173 17.6377 7.81031 17.8068 7.81031H23.2251V28.5483H19.9104L18.8538 29.85H24.5V6.56391L18.0713 0ZM18.4442 6.50859V2.54649L22.3247 6.50859H18.4442Z" />
                    <path d="M18.4815 25.323V18.7705H12.8302V25.323H10.3359L15.655 31.5745L20.9741 25.323H18.4815Z" />
                    <path d="M19.7715 8.78681C19.3287 8.78681 18.9041 8.61538 18.591 8.31023C18.2779 8.00508 18.102 7.59121 18.102 7.15966V5.41699H1.64062V13.7154H20.7732V8.78519L19.7715 8.78681Z" />
                    <path d="M2.36719 12.0341V7.12176H3.92735C4.31391 7.10611 4.70103 7.13064 5.08273 7.19498C5.36016 7.26973 5.60421 7.4391 5.77436 7.67499C5.96984 7.95292 6.06807 8.28992 6.05324 8.63175C6.06284 8.89516 6.00807 9.15686 5.89388 9.39326C5.79812 9.58697 5.65821 9.75438 5.48591 9.88141C5.33442 9.99157 5.16338 10.0706 4.98233 10.1141C4.6493 10.1679 4.31225 10.1913 3.97516 10.1841H3.33771V12.0374L2.36719 12.0341ZM3.3393 7.95161V9.34607H3.87157C4.12993 9.36052 4.38894 9.33473 4.6397 9.2696C4.76268 9.22256 4.86843 9.13788 4.94249 9.02715C5.01708 8.91502 5.05552 8.78191 5.05245 8.6464C5.05747 8.48336 5.00034 8.3247 4.89308 8.20381C4.79135 8.08748 4.65347 8.01048 4.50265 7.98578C4.27118 7.95618 4.03782 7.94476 3.80464 7.95161H3.3393Z" style={{ fill: 'white' }} />
                    <path
                      d="M6.85059 7.12165H8.62588C8.93399 7.11141 9.24208 7.1426 9.54222 7.2144C9.81914 7.29636 10.0695 7.45255 10.2673 7.66675C10.4746 7.89514 10.6312 8.16655 10.7263 8.46242C10.8409 8.83827
                      10.8947 9.23068 10.8856 9.6242C10.893 9.97891 10.843 10.3324 10.7374 10.6705C10.6356 10.9971 10.4592 11.2944 10.2227 11.5377C10.0274 11.7205 9.79345 11.8547 9.53903 11.9299C9.2594 12.0082
                       8.96987 12.0433 8.68007 12.034H6.85059V7.12165ZM7.8211 7.9515V11.2058H8.5462C8.74334 11.2132 8.94067 11.1974 9.13425 11.1586C9.27966 11.1257 9.41454 11.0557 9.52628 10.9552C9.65066 10.8224
                       9.7386 10.6586 9.78126 10.4801C9.85569 10.1864 9.88895 9.88347 9.88007 9.58027C9.89023 9.2856 9.85692 8.99105 9.78126 8.70649C9.72966 8.52662 9.63489 8.36275 9.50556 8.22974C9.37952 8.11184
                        9.22398 8.03187 9.05616 7.99868C8.79248 7.95853 8.5258 7.94274 8.25935 7.9515H7.8211Z"
                      style={{ fill: 'white' }}
                    />
                    <path d="M11.717 12.0339V7.12158H15.0222V7.95305H12.6955V9.11646H14.7035V9.94793H12.6955V12.0356L11.717 12.0339Z" style={{ fill: 'white' }} />
                  </svg>
                </div>
                <p className="thank-you-share-text">{t('TyPageDownload')}</p>
              </button>
            </div>
          </div>

          {/* POGLEDI VRAT, POVZETEK KONFIGURACIJE */}
          <div className="thank-you-canvas">
            <div className="thank-you-door-info">
              {/* ZUNANJA/NOTRANJA VRATA */}
              <div className="thank-you-door-view">
                {/* OUTSIDE DOOR VIEW */}
                <div className="thank-you-door-view-section">
                  <div className="thank-you-door-view-section-outside0">
                    <div className="thank-you-door-view-section-outside1">
                      <div id="svgContainer" className="thank-you-door-view-outside" dangerouslySetInnerHTML={{ __html: doorViewOutside }} />

                      {/* ++BARVA OZADJA */}
                      {/* <div id="svgContainer" className={"show-door show-door--review selected-door background" + `${form.BGColor}`} dangerouslySetInnerHTML={{ __html: doorSvg }}> */}
                    </div>
                  </div>
                </div>
                {/* INSIDE DOOR VIEW */}
                <div className="thank-you-door-view-section2">
                  <div className="thank-you-door-view-section-outside0">
                    <div className="thank-you-door-view-section-outside1">
                      <div id="svgContainer" className="thank-you-door-view-inside" dangerouslySetInnerHTML={{ __html: doorViewInside }} />
                    </div>
                  </div>
                </div>
              </div>

              {/* SPISEK KONFIGURACIJE */}
              <div className="thank-you-summary">
                <i className="thank-you-summary-line" />
                <div className="thank-you-summary-toggle">
                  <h2 className="thank-you-summary-conf">{t('confSummary')}</h2>
                  <i
                    className={'cursor-pointer ' + (!summary ? 'ico ico-plus' : 'ico ico-minus')}
                    onClick={() => {
                      setSummary(!summary);
                    }}
                  />
                </div>

                <i className="thank-you-summary-line" />

                {summary && (
                  <div className="summary-mobile">
                    {form.modelName && (
                      <div className="thank-you-summary-show">
                        <div className="thank-you-summary-frame">
                          <p className="thank-you-summary-frame-title">{t('InqModel')}</p>
                          <div className="thank-you-summary-frame-info">
                            {/* <img src="" className="thank-you-summary-frame-image-svg" /> */}
                            <div id="svgContainer" className="thank-you-summary-frame-image-svg" dangerouslySetInnerHTML={{ __html: doorSvg }}></div>
                            <p className="thank-you-summary-frame-text">{form.modelName}</p>
                          </div>
                        </div>
                      </div>
                    )}
                    <i className="thank-you-summary-show-line" />
                    {(form.doorOpeningLeftSide || form.doorOpeningRightSide) && (
                      <>
                        <div className="thank-you-summary-show">
                          <div className="thank-you-summary-frame">
                            <p className="thank-you-summary-frame-title">{t('confOpening')}</p>
                            <div className="thank-you-summary-frame-info">
                              <p className="thank-you-summary-frame-text">{form.doorOpeningLeftSide ? t('confLeft') : t('confRight')}</p>
                            </div>
                          </div>
                        </div>
                        <i className="thank-you-summary-show-line" />
                      </>
                    )}
                    {form.dimensionHeight && form.dimensionWidth && (
                      <>
                        <div className="thank-you-summary-show">
                          <div className="thank-you-summary-frame">
                            <p className="thank-you-summary-frame-title">{t('confDimensions')}</p>
                            <div className="thank-you-summary-frame-info">
                              <p className="thank-you-summary-frame-text">
                                {form.dimensionHeight}mm x {form.dimensionWidth}mm
                              </p>
                            </div>
                          </div>
                        </div>
                        <i className="thank-you-summary-show-line" />
                      </>
                    )}
                    {(form.colorWingOutside || form.colorAplikacijeOutside || form.colorDekorOutside || form.colorOkvirckiOutside || form.colorFrameOutside || form.colorWingInside || form.colorAplikacijeInside || form.colorDekorInside || form.colorOkvirckiInside || form.colorFrameInside) && (
                      <>
                        <div className="thank-you-summary-show">
                          <div className="thank-you-summary-frame">
                            <p className="thank-you-summary-frame-title">{t('confColorsOutside')}</p>
                            <div className="thank-you-summary-frame-color-wrapper">
                              <ActiveColors colorArray={form.colorWingOutside} title={t('confColWing')} />
                              <ActiveColors colorArray={form.colorAplikacijeOutside} title={t('confAppOut')} />
                              <ActiveColors colorArray={form.colorDekorOutside} title={t('confDekorOut')} />
                              <ActiveColors colorArray={form.colorOkvirckiOutside} title={t('confWindowsOut')} />
                              <ActiveColors colorArray={form.colorFrameOutside} title={t('confColFrame')} />
                            </div>
                          </div>
                        </div>
                        <i className="thank-you-summary-show-line" />
                        <div className="thank-you-summary-show">
                          <div className="thank-you-summary-frame">
                            <p className="thank-you-summary-frame-title">{t('confColorsInside')}</p>
                            <div className="thank-you-summary-frame-color-wrapper">
                              <ActiveColors colorArray={form.colorWingInside} title={t('confColWing')} />
                              <ActiveColors colorArray={form.colorAplikacijeInside} title={t('confAppIn')} />
                              <ActiveColors colorArray={form.colorDekorInside} title={t('confDekorIn')} />
                              <ActiveColors colorArray={form.colorOkvirckiInside} title={t('confWindowsIn')} />
                              <ActiveColors colorArray={form.colorFrameInside} title={t('confColFrame')} />
                            </div>
                          </div>
                        </div>
                        <i className="thank-you-summary-show-line" />
                      </>
                    )}
                    {form.doorHasGlass && ((form.motivType && form.motivType !== -1) || (form.glassType && form.glassType !== -1)) && (
                      <>
                        <div className="thank-you-summary-show">
                          <div className="thank-you-summary-frame">
                            <p className="thank-you-summary-frame-title">{t('confGlass')}</p>

                            {form.motivType && form.motivType !== -1 && (
                              <div className="thank-you-summary-frame-info-column">
                                <div className="thank-you-summary-frame-info">
                                  <img src={url + motivnaModel[0].id.previewSlika} alt="glass" className="thank-you-summary-image" />
                                  <p className="thank-you-summary-frame-text">{motivnaModel[0].id.model}</p>
                                </div>
                              </div>
                            )}
                            {form.glassType && form.glassType !== -1 && (
                              <div className="thank-you-summary-frame-info-column">
                                <div className="thank-you-summary-frame-info">
                                  <img src={url + ornamentnaModel.previewPath} alt="glass" className="thank-you-summary-image" />
                                  <p className="thank-you-summary-frame-text">{ornamentnaModel.model}</p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <i className="thank-you-summary-show-line" />
                      </>
                    )}
                    {(form.outsideHandle || form.insideHandle) && (
                      <>
                        <div className="thank-you-summary-show">
                          {!isMobile ? (
                            <div className="thank-you-summary-frame">
                              <p className="thank-you-summary-frame-title">{t('confHandle')}</p>
                              {form.outsideHandle && outsideHandle && (
                                <div className="thank-you-summary-frame-info">
                                  <img src={url + outsideHandle.imagePath} alt="Zunanja držala" className="thank-you-summary-image" />
                                  <div className="thank-you-summary-frame-section">
                                    <div className="thank-you-summary-frame-section-title">
                                      <p className="thank-you-summary-frame-section-title-small">{t('InqLeverOutside')}</p>
                                    </div>
                                    <div className="thank-you-summary-frame-section-title2">
                                      <div className="thank-you-summary-frame-section-title3">
                                        <p className="thank-you-summary-frame-section-title-big">{outsideHandle.model}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {form.insideHandle && (
                                <div className="thank-you-summary-frame-info">
                                  <img src={url + insideHandleModel.previewPath} alt="Notranja držala" className="thank-you-summary-image" />
                                  <div className="thank-you-summary-frame-section">
                                    <div className="thank-you-summary-frame-section-title">
                                      <p className="thank-you-summary-frame-section-title-small">{t('InqLeverInside')}</p>
                                    </div>
                                    <div className="thank-you-summary-frame-section-title2">
                                      <div className="thank-you-summary-frame-section-title3">
                                        <p className="thank-you-summary-frame-section-title-big">{insideHandleModel.model}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="thank-you-summary-frame">
                              <p className="thank-you-summary-frame-title">{t('confHandle')}</p>
                              <div className="thank-you-summary-frame-color-wrapper">
                                {form.outsideHandle && outsideHandle && (
                                  <div className="thank-you-summary-frame-info">
                                    <img src={url + outsideHandle.imagePath} alt="Zunanja držala" className="thank-you-summary-image" />
                                    <div className="thank-you-summary-frame-section">
                                      <div className="thank-you-summary-frame-section-title">
                                        <p className="thank-you-summary-frame-section-title-small">{t('InqLeverOutside')}</p>
                                      </div>
                                      <div className="thank-you-summary-frame-section-title2">
                                        <div className="thank-you-summary-frame-section-title3">
                                          <p className="thank-you-summary-frame-section-title-big">{outsideHandle.model}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {form.insideHandle && (
                                  <div className="thank-you-summary-frame-info">
                                    <img src={url + insideHandleModel.previewPath} alt="Notranja držala" className="thank-you-summary-image" />
                                    <div className="thank-you-summary-frame-section">
                                      <div className="thank-you-summary-frame-section-title">
                                        <p className="thank-you-summary-frame-section-title-small">{t('InqLeverInside')}</p>
                                      </div>
                                      <div className="thank-you-summary-frame-section-title2">
                                        <div className="thank-you-summary-frame-section-title3">
                                          <p className="thank-you-summary-frame-section-title-big">{insideHandleModel.model}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                        <i className="thank-you-summary-show-line" />
                      </>
                    )}
                    {form.lockingType && (
                      <>
                        <div className="thank-you-summary-show">
                          <div className="thank-you-summary-frame">
                            <p className="thank-you-summary-frame-title">{t('confLock')}</p>
                            <div className="thank-you-summary-frame-info-column">
                              <div className="thank-you-summary-frame-info">
                                <img src={url + lockModel.previewPath} alt="Zaklepanje" className="thank-you-summary-image" />
                                <p className="thank-you-summary-frame-text">{lockModel.model}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {!!(form.houseNumber || form.dripBatten || form.knocker || form.peephole) && <i className="thank-you-summary-show-line" />}
                      </>
                    )}
                    {!!(form.houseNumber || form.dripBatten || form.knocker || form.peephole) && (
                      <div className="thank-you-summary-show">
                        <div className="thank-you-summary-frame">
                          <p className="thank-you-summary-frame-title">{t('confExtras')}</p>
                          {form.houseNumberIMG && (
                            <div className="thank-you-summary-frame-info">
                              <img src={url + houseNumberImage[0].previewPath} alt="hišna številka" className="thank-you-summary-image" />
                              <div className="thank-you-summary-frame-section">
                                <div className="thank-you-summary-frame-section-title">
                                  <p className="thank-you-summary-frame-section-title-small">{t('InqHouseNo')}</p>
                                </div>
                                <div className="thank-you-summary-frame-section-title2">
                                  <div className="thank-you-summary-frame-section-title3">
                                    <p className="thank-you-summary-frame-section-title-big">{form.houseNumber}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {form.dripBatten && (
                            <div className="thank-you-summary-frame-info">
                              <img src={url + dripBattenModel.previewPath} alt="Odkapna letev" className="thank-you-summary-image" />
                              <div className="thank-you-summary-frame-section">
                                <div className="thank-you-summary-frame-section-title">
                                  <p className="thank-you-summary-frame-section-title-small">{t('InqDripBattens')}</p>
                                </div>
                                <div className="thank-you-summary-frame-section-title2">
                                  <div className="thank-you-summary-frame-section-title3">
                                    <p className="thank-you-summary-frame-section-title-big">{dripBattenModel.model}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {!!(form.knocker && form.knocker !== 0) && (
                            <div className="thank-you-summary-frame-info">
                              <img src={url + knockerModel.previewPath} alt="Tolkalo" className="thank-you-summary-image" />
                              <div className="thank-you-summary-frame-section">
                                <div className="thank-you-summary-frame-section-title">
                                  <p className="thank-you-summary-frame-section-title-small">{t('InqKnocker')}</p>
                                </div>
                                <div className="thank-you-summary-frame-section-title2">
                                  <div className="thank-you-summary-frame-section-title3">
                                    <p className="thank-you-summary-frame-section-title-big">{knockerModel.model}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {!!(form.peephole && form.peephole !== 0) && (
                            <div className="thank-you-summary-frame-info">
                              <img src={url + peepholeModel.previewPath} alt="Kukalo" className="thank-you-summary-image" />
                              <div className="thank-you-summary-frame-section">
                                <div className="thank-you-summary-frame-section-title">
                                  <p className="thank-you-summary-frame-section-title-small">{t('InqPeephole')}</p>
                                </div>
                                <div className="thank-you-summary-frame-section-title2">
                                  <div className="thank-you-summary-frame-section-title3">
                                    <p className="thank-you-summary-frame-section-title-big">{peepholeModel.model}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="thank-you-desc">
              <p className="thank-you-description">
                {t('TyPageDescription1')}
                <br />
                <br />
                {t('TyPageDescription2')}
              </p>
            </div>

            {/* PODATKI STRANKE */}
            <div className="thank-you-customer">
              <h1 className="thank-you-customer-info-header">{t('CustomerInformation')}</h1>

              <div className="thank-you-customer-info">
                <h2 className="thank-you-customer-contacts">{t('CustomerContact')}</h2>
                <div className="thank-you-customer-info-elements">
                  <div className="thank-you-customer-info-section">
                    <div className="thank-you-customer-frame">
                      <div className="thank-you-customer-frame-in">
                        <p className="thank-you-customer-frame-in2">{t('CustomerNameSurname')}</p>
                      </div>

                      <div className="thank-you-customer-frame-in1">
                        <div className="thank-you-customer-frame-in3">
                          <p className="thank-you-customer-frame-in4">
                            {form.customerName} {form.customerSurname}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="thank-you-customer-info-section">
                    <div className="thank-you-customer-frame">
                      <div className="thank-you-customer-frame-in">
                        <p className="thank-you-customer-frame-in2">{t('CustomerAddress')}</p>
                      </div>

                      <div className="thank-you-customer-frame-in1">
                        <div className="thank-you-customer-frame-in3">
                          <p className="thank-you-customer-frame-in4">{form.customerAddress}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="thank-you-customer-info-section">
                    <div className="thank-you-customer-frame">
                      <div className="thank-you-customer-frame-in">
                        <p className="thank-you-customer-frame-in2">{t('CustomerPostCity')}</p>
                      </div>

                      <div className="thank-you-customer-frame-in1">
                        <div className="thank-you-customer-frame-in3">
                          <p className="thank-you-customer-frame-in4">
                            {form.customerPostNo} {form.customerCity}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="thank-you-customer-info-section">
                                        <div className="thank-you-customer-frame">
                                            <div className="thank-you-customer-frame-in">
                                                <p className="thank-you-customer-frame-in2">{t('CustomerCountry')}</p>
                                            </div>

                                            <div className="thank-you-customer-frame-in1">
                                                <div className="thank-you-customer-frame-in3">
                                                    <p className="thank-you-customer-frame-in4">Slovenija</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                  <div className="thank-you-customer-info-section">
                    <div className="thank-you-customer-frame">
                      <div className="thank-you-customer-frame-in">
                        <p className="thank-you-customer-frame-in2">{t('CustomerEmail')}</p>
                      </div>

                      <div className="thank-you-customer-frame-in1">
                        <div className="thank-you-customer-frame-in3">
                          <p className="thank-you-customer-frame-in4">{form.customerEmail}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="thank-you-customer-info-section">
                    <div className="thank-you-customer-frame">
                      <div className="thank-you-customer-frame-in">
                        <p className="thank-you-customer-frame-in2">{t('CustomerPhone')}</p>
                      </div>
                      <div className="thank-you-customer-frame-in1">
                        <div className="thank-you-customer-frame-in3">
                          <p className="thank-you-customer-frame-in4">{form.customerPhone}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="thank-you-customer-info-section">
                    <div className="thank-you-customer-frame">
                      <div className="thank-you-customer-frame-in">
                        <p className="thank-you-customer-frame-in2">{t('CustomerNote')}</p>
                      </div>

                      <div className="thank-you-customer-frame-in1">
                        <div className="thank-you-customer-frame-in3">
                          <p className="thank-you-customer-frame-in4">{form.customerNote}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* LAST SECTION IS DIFFERENT THAN THE REST - QUESTION */}
                  <div className="thank-you-customer-frame-question">
                    <div className="thank-you-customer-frame-question-title">
                      <div className="thank-you-customer-frame-question-title1">
                        <div className="thank-you-customer-frame-question-title2">
                          <div className="thank-you-customer-frame-question-title3">
                            <p className="thank-you-customer-frame-question-text">{t('CustomerDoorSetup')}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="thank-you-customer-frame-in1">
                      <div className="thank-you-customer-frame-in3">
                        <p className="thank-you-customer-frame-in4">{getDoorNeed()}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* FOOTER */}
        <footer className="thank-you-footer">
          <div className="thank-you-footer-elements">
            {/* <p className="thank-you-footer-policy">Politika varstva zasebnosti</p> */}
            <div className="thank-you-footer-info">
              <p className="thank-you-footer-2020">
                {t('TyPageCopyRights')} © {partnerParameters && partnerParameters.companyInfoText ? partnerParameters.companyInfoText : ''}
              </p>
              {/* <p className="thank-you-footer-design">Oblikovanje:</p>
                            <i className="thank-you-footer-logo logo__image" /> */}
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default ThankYouPage;
