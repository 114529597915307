export const translationSl = {
  modelType: "Tip modela",
  chooseModel: "PROGRAMI VHODNIH VRAT",
  glass: "Količina stekla",
  modelShape: "Oblika stekla",
  SearchApp: "Iskanje aplikacij",
  chosenModel: "Izbrani model vrat",
  configureDoors: "Sestavi svoja vrata",
  allDoors: "Vsi",
  findModel: "Poišči model vrat",
  showResults: "Prikaži rezultate",
  filter: "Filtri",
  save: "Shrani in nadaljuj pozneje",
  sendInquiry: "Pošlji povpraševanje",
  headerModel: "Izbor modela vrat",
  configurator: "Konfigurator",
  confDoorOpening: "Smer odpiranja",
  confLeft: "Levo",
  confRight: "Desno",
  confConstructionType: "TIP KONSTRUKCIJE",
  confDimensions: "Dimenzije",
  confHeight: "Višina",
  confWidth: "Širina",
  confHeightExample: "Npr. 2100 mm",
  confWidthExample: "Npr. 1050 mm",
  confExample: "Npr.",
  confSkipDimensions: "Če trenutno nimate informacij o dimenzijah, lahko ta korak preskočite",
  confColors: "Barva",
  confColWhole: "Barvanje celote",
  confColAdvanced: "Napredno barvanje",
  confCol1: "Barva 1",
  confCol2: "Barva 2",
  confMoreColors: "Prikaži več barv",
  confColorTop: "TOP 10 najbolj prodajanih barv",
  confFindColor: "Poišči barvo",
  confColorGroup: "BARVNE SKUPINE",
  confColorShade: "Barvni odtenek",
  confColMa: "Mat",
  confColSt: "Strukturna",
  confColShine: "Sijaj",
  confColLess: "Skrij več barv",
  confColReset: "Ponastavi",
  confColWing: "Krilo",
  confColOutside: "Zunaj",
  confColInside: "Znotraj",
  confColFrame: "Okvir vrat",
  confGlass: "Stekla",
  confMotGlass: "MOTIVNA STEKLA",
  confOrnGlass: "ORNAMENTNA STEKLA",
  confHandle: "Držala",
  confHandleOutside: "DRŽALO ZUNAJ",
  confHandleInside: "Notranja kljuka",
  confHandleDesc: "Opis",
  confHandleDim: "Dimenzije",
  confLock: "ZAKLEPANJE",
  confLockTop: "Najbolj popularni sistemi zaklepanja",
  confLockTypes: "Prikaži več načinov za zaklepanje",
  confLockAll: "vsi sistemi zaklepanja",
  confLockLess: "Skrij več načinov zaklepanja",
  confExtras: "Dodatki",
  confExtrasBattens: "Odkapna letev",
  confSummary: "Povzetek konfiguracije",
  confContinue: "Nadaljuj pozneje",
  confLook: "Pogled",
  confBackground: "Ozadje",
  confPicture: "Slika",
  confChooseModel: "Izbor modela vrat",
  confPickedModel: "Izbrani model",
  confSwitchModel: "Zamenjaj model",
  confHouseNo: "Hišna številka",
  confPeephole: "Kukalo",
  confKnocker: "Tolkalo",
  confHouseInput: "Vpišite vašo hišno številko",
  confAdjust: "Prilagodi",
  confData: "Vaši podatki",
  InqName: "Ime",
  InqSurname: "Priimek",
  InqAddress: "Ulica in hišna številka",
  InqPost: "Poštna št.",
  InqCity: "Kraj",
  InqEmail: "E-mail",
  InqEmDesc: "Vaš elektronski naslov potrebujemo zato, da vam pošljemo okvirno ponudbo za vaša vhodna vrata.",
  InqPhone: "Telefon",
  InqPhoneDesc: "Vašo telefonsko številko potrebujemo le v primeru, če bi potrebovali še kakšno dodatno informacijo, za pripravo vaše ponudbe.",
  InqMessage: "Nam želite kaj sporočiti?",
  InqDoor: "Vrata potrebujete za menjavo ali novogradnjo?",
  InqMark: "Ustrezno oznacite",
  InqNew: "Novogradnja",
  InqChange: "Menjava vrat",
  InqContact: "Kontaktirajte me glede svetovanja, prodaje in vgradnje.",
  InqAgreement: "Strinjam se, da bodo z namenom svetovanja ter ponudbe, moji kontaktni podatki posredovani prodajalcu oziroma avtoriziranemu partnerju.",
  InqNews: "Pošljite mi brezplačen izvod kataloga.",
  InqFinish: "Oddaj povpraševanje",
  InqRequired: "Obvezno polje",
  InqSummary: "Povzetek konfiguracije",
  InqInvalidEMail: "Neveljavna e-pošta",
  InqConfSummary: "Spisek celotne konfiguracije",
  InqPDF: "Prenesi v PDF",
  InqShare: "Deli povezavo",
  InqVisit: "Obišči naš v salonu",
  InqModel: "Model",
  InqColOutside: "Zunaj",
  InqColInside: "Znotraj",
  InqLeverInside: "Notranja kljuka",
  InqLeverOutside: "Držalo",
  InqHouseNo: "Hišna številka",
  InqDripBattens: "Odkapna letev",
  InqKnocker: "Tolkalo",
  InqPeephole: "Kukalo",
  close: "Zapri",
  classicType: "Klasični",
  modernType: "Moderni",
  extravagantType: "Ekstravagantni",
  simpleType: "Enostavni",
  topSeller: "Najbolj prodajani",
  glassNone: "Brez",
  glassSmall: "Malo",
  glassMedium: "Srednje",
  glassBig: "Veliko",
  shapeRectangle: "Pravokotniki",
  shapeSquare: "Kvadrati",
  shapeCurves: "Okrogline in loki",
  shapeOther: "Ostale oblike",
  searchMulticolor: "Večbarvno",
  searchInox: "Inox aplikacije",
  searchUtori: "Utori",
  BGColor: "Izberite barvo ozadja",
  SelectBGColor: "Izberi barvo",
  BGPictureTitle: "Vstavite sliko",
  BGPictureDesc: "Vstavite sliko vaše hiše ali izberite sliko iz spodnjega nabora fotografij.",
  BGPicturestep1: "1. korak",
  BGPictureTip1: "naloži ali izberi sliko",
  BGPicturestep2: "2. korak",
  BGPictureTip2: "pozicioniraj vrata",
  BGPicturestep3: "3. korak",
  BGPictureTip3: "nadaljuj s konfiguracijo",
  BGPictureUpload: "naloži sliko iz računalnika",
  BGPictureDesc2: "ali izberi sliko iz spodnjega nabora fotografij",
  BGPictureChoose: "UPORABI SLIKO",
  confOpening: "Odpiranje",
  confThankYouPage: "Zahvaljujemo se za povpraševanje!",
  noModelResults: "Ni rezultatov",
  confAppOut: "Aplikacija zunaj",
  confAppIn: "Aplikacija znotraj",
  confDekorOut: "Dekor zunaj",
  confDekorIn: "Dekor znotraj",
  confWindowsOut: "Okvircki zunaj",
  confWindowsIn: "Okvircki znotraj",
  confHandleOutsideMainColor: "Izbrana barva",
  CustomerInformation: "Vaši podatki",
  CustomerContact: "Kontaktni podatki",
  CustomerNameSurname: "Ime in priimek",
  CustomerAddress: "Ulica in hišna številka",
  CustomerPostCity: "Poštna številka in kraj",
  CustomerCountry: "Država",
  CustomerEmail: "E-MAIL naslov",
  CustomerPhone: "Telefonska številka",
  CustomerNote: "Opombe k naročilu",
  CustomerDoorSetup: "Vrata potrebujete za menjavo ali novogradnjo?",
  TyPageDownload: "Prenesi konfiguracijo v pdf dokument",
  TyPageShare: "Deli povezavo do konfiguracije",
  TyPageAppreciation: "Veseli in počaščeni smo, da se zanimate za nakup {{companyInfo}} vhodnih vrat. Trdno verjamemo, da bi se odlično podala k vašemu pročelju, obenem pa bi se z njihovo izbiro pridružili na tisoče zadovoljnim uporabnikom po vsem svetu, katerih domove že krasijo vhodna vrata Inotherm.",
  TyPageHeader: "Hvala za povpraševanje!",
  TyPageDescription1: "V zgornji tabeli si lahko ogledate vse podrobnosti svoje trenutne konfiguracije, za katero boste v najkrajšem možnem času prejeli tudi neobvezujočo ponudbo.",
  TyPageDescription2: "V kratkem vas bo kontaktiral tudi naš prodajni strokovnjak, s katerim se lahko dogovorite glede nadaljnjih korakov, kot so spremembe konfiguracije, izmera zidne odprtine in končno naročilo.",
  model: "Model",
  confHandleOutsideExtraColoring: "Dodatne barve",
  confHandleOutsideColor: "Barva držala",
  confHandleOutsideAdditionalColor: "Barva poglobitve",
  confColorsOutside: "Zunanje barve",
  confColorsInside: "Notranje barve",
  TyPageCopyRights: "Vse pravice pridržane 2023",
  logoLink: "https://www.inotherm.si",
  InoWebsite: "Na našo spletno stran",
  positionHouseNum: "Pozicija hišne številke na vratih",
  positionKnocker: "Pozicija tolkala na vratih",
  positionPeephole: "Pozicija kukala na vratih",
  positionInfo: "S pomočjo spodnjega orodja postavite dodatno opremo  na želeno mesto na vratih.",
  positionHorizontal: "VODORAVNO",
  positionVertical: "NAVPIČNO",
  headerFilterVsi: "Vsi modeli",
  headerFilterAkcija24: "Trend 24",
  headerFilterZimska: "Zimska akcija",
  headerFilterTop: "Top modeli",
  headerFilterAkcija: "Black Edition",
  openFilters: "FILTRI",
  clearFilters: "Počisti filtre",
  useFilters: "Prikaži rezultat",
  bannerText: "IZBERITE MODEL VHODNIH VRAT IN PRIČNITE Z OBLIKOVANJEM SVOJEGA VHODA",
  miniCokel: "Nizek parapet",
  monthlyTopModel: "Vrata meseca",
  blackEditionTitle: "Black Edition",
  blackEditionDesc: "Nadgradite dizajn svojih sanjskih vrat z brezčasnostjo črne.",
  blackEditionAdd: "DODAJ",
  blackEditionRemove: "ODSTRANI",
}
