export const translationDe = {
	modelType: "Modelle",
	chooseModel: "ALU HAUSTÜREN PROGRAMME",
	glass: "Glas",
	modelShape: "Glasform",
	SearchApp: "Applikationen am Modell",
	chosenModel: "Ausgewähltes Modell",
	configureDoors: "Türe konfigurieren",
	allDoors: "Alle",
	findModel: "Modell suchen",
	showResults: "Ergebnis anzeigen",
	filter: "Filter",
	save: "Speichern und später fortfahren",
	sendInquiry: "Anfrage senden",
	headerModel: "MODELL AUSWÄHLEN",
	configurator: "Konfigurator",
	confDoorOpening: "DIN Richtung",
	confLeft: "Links",
	confRight: "Rechts",
	confConstructionType: "Typ und Konstruktion",
	confDimensions: "Dimensionen",
	confHeight: "Höhe",
	confWidth: "Breite",
	confHeightExample: "z.B. 2100 mm",
	confWidthExample: "z.B. 1050 mm",
	confExample: "z.B.",
	confSkipDimensions: "Wenn Sie im Moment keine Dimensionsinformationen haben, können Sie diesen Schritt überspringen",
	confColors: "Farben",
	confColWhole: "ALLE ELEMENTE FÄRBEN",
	confColAdvanced: "Erweiterte Farbauswahl",
	confCol1: "Farbe 1",
	confCol2: "Farbe 2",
	confMoreColors: "Mehr Farben anzeigen",
	confColorTop: "TOP 10 der meistverkauften Farben",
	confFindColor: "Farbe suchen",
	confColorGroup: "FARBEN GRUPPE",
	confColorShade: "Farbton",
	confColMa: "Matt",
	confColSt: "Feinstruktur",
	confColShine: "Glänzend",
	confColLess: "Farben ausblenden",
	confColReset: "Rücksetzen",
	confColWing: "Flügel",
	confColOutside: "Aussen",
	confColInside: "Innen",
	confColFrame: "Blendrahmen",
	confGlass: "Gläser",
	confMotGlass: "DESIGN MOTIVE",
	confOrnGlass: "ORNAMENTGLAS",
	confHandle: "Griffe",
	confHandleOutside: "GRIFF AUSSEN",
	confHandleInside: "Innendrücker",
	confHandleDesc: "Beschreibung",
	confHandleDim: "Dimensionen",
	confLock: "VERRIEGELUNG",
	confLockTop: "Das beliebteste Schließsystem",
	confLockTypes: "Mehr Varianten anzeigen",
	confLockAll: "Alle Schließsysteme",
	confLockLess: "Schließsysteme ausblenden",
	confExtras: "Zubehör",
	confExtrasBattens: "Wetterschenkel",
	confSummary: "Konfigurationszusammenfassung",
	confContinue: "Später fortfahren",
	confLook: "Ansicht",
	confBackground: "Hintergrund",
	confPicture: "Bild",
	confChooseModel: "Modell auswählen",
	confPickedModel: "Ausgewähltes Modell",
	confSwitchModel: "Modell tauschen",
	confHouseNo: "Hausnummer",
	confPeephole: "Türspione",
	confKnocker: "Türklopfer",
	confHouseInput: "Gebe Sie Ihre Hausnummer ein",
	confAdjust: "Anpassen",
	confData: "Ihre Daten",
	InqName: "Vorname",
	InqSurname: "Nachname",
	InqAddress: "Straße, Nr.",
	InqPost: "PLZ",
	InqCity: "Ort",
	InqEmail: "E-mail",
	InqEmDesc: "Ihre E-Mail-Adresse benötigen wir, um Ihnen ein unverbindliches Angebot für Ihre Haustür zukommen zu lassen.",
	InqPhone: "Telefon",
	InqPhoneDesc: "Ihre Telefonnummer benötigen wir nur für den Fall, wenn wir weitere Informationen zur Angebotserstellung benötigen.",
	InqMessage: "Möchten Sie uns etwas mitteilen?",
	InqDoor: "Handelt es sich um eine Sanierung oder einen Neubau?",
	InqMark: "Entsprechend angeben",
	InqNew: "Neubau",
	InqChange: "Sanierung",
	InqContact: "Kontaktaufnhame wegen Beratung, Verkauf und Montage.",
	InqAgreement: "Ich bin damit einverstanden, dass meine Kontaktdaten zum Zwecke der Beratung und Angebotserstellung an den Verkäufer oder einen autorisierten Partner weitergegeben werden.",
	InqNews: "Kostenlose und unverbindliche Zusendung von Prospekten.",
	InqFinish: "Anfrage senden",
	InqRequired: "Pflichtfeld",
	InqSummary: "Konfigurationszusammenfassung",
	InqInvalidEMail: "Ungültige E-Mail",
	InqConfSummary: "Liste der vollständigen Konfiguration",
	InqPDF: "Als PDF herunterladen",
	InqShare: "Link teilen",
	InqVisit: "Besuchen Sie unsere Ausstellung",
	InqModel: "Modell",
	InqColOutside: "Aussen",
	InqColInside: "Innen",
	InqLeverInside: "Innendrücker",
	InqLeverOutside: "Griff-Aussen",
	InqHouseNo: "Hausnummer",
	InqDripBattens: "Wetterschenkel",
	InqKnocker: "Türklopfer",
	InqPeephole: "Türspione",
	close: "Schliessen",
	classicType: "Klassisch",
	modernType: "Modern",
	extravagantType: "Extravagant",
	simpleType: "Einfach",
	topSeller: "Spitzenreiter",
	glassNone: "Ohne",
	glassSmall: "Klein",
	glassMedium: "Mittel",
	glassBig: "Gross",
	shapeRectangle: "Rechtecke",
	shapeSquare: "Quadrate",
	shapeCurves: "Kurven und Bogen",
	shapeOther: "Andere Formen",
	searchMulticolor: "Mehrfarbig",
	searchInox: "Edelst. Applikationen",
	searchUtori: "Relief",
	BGColor: "Hintergrundfarbe wählen",
	SelectBGColor: "Farbe wählen",
	BGPictureTitle: "Bild einfügen",
	BGPictureDesc: "Fügen Sie ein Bild Ihres Hauses ein oder wählen Sie ein Bild aus den folgenden Fotos aus.",
	BGPicturestep1: "1. Schritt",
	BGPictureTip1: "Bild hochladen oder auswählen",
	BGPicturestep2: "2. Schritt",
	BGPictureTip2: "Türe positionieren",
	BGPicturestep3: "3. Schritt",
	BGPictureTip3: "Konfiguration fortfahren",
	BGPictureUpload: "Hochladen eines Bildes von Ihrem Computer",
	BGPictureDesc2: "oder wählen Sie eines der unteren Bilder aus",
	BGPictureChoose: "DAS BILD VERWENDEN",
	confOpening: "DIN Richtung",
	confThankYouPage: "Wir bedanken uns für Ihre Anfrage.",
	noModelResults: "Keine Ergebnisse gefunden.",
	confAppOut: "Applikation außen",
	confAppIn: "Applikation innen",
	confDekorOut: "Dekor außen",
	confDekorIn: "Dekor innen",
	confWindowsOut: "Aluminium Ornamentrahmen außen",
	confWindowsIn: "Aluminim Ornamentrahmen innen",
	confHandleOutsideMainColor: "Ausgewählte Farbe",
	CustomerInformation: "Ihre Angaben",
	CustomerContact: "Kontaktangaben",
	CustomerNameSurname: "Vor- und Nachname",
	CustomerAddress: "Straße und Hausnummer",
	CustomerPostCity: "Postleitzahl und Ort",
	CustomerCountry: "Land",
	CustomerEmail: "E-Mail Adresse",
	CustomerPhone: "Telefonnummer",
	CustomerNote: "Sonderwunsch",
	CustomerDoorSetup: "Benötigen Sie die Tür für eine Sanierung oder einen Neubau?",
	TyPageDownload: "Die Konfiguration in PDF umwandeln.",
	TyPageShare: "Einen Link zur Konfiguration weitergeben.",
	TyPageAppreciation: "Wir freuen uns und fühlen uns sehr geehrt, dass Sie sich für den Kauf einer Aluminium-Haustür interessieren. Wir sind fest davon überzeugt, dass die Tür perfekt zu Ihrem Haus passen würde. Wenn Sie sich für sie entscheiden, gehören Sie zu den Tausenden von den zufriedenen Kunden auf der ganzen Welt, deren Häuser bereits mit der Aluminium-Haustür ausgestattet sind.",
	TyPageHeader: "Vielen Dank für Ihre Anfrage!",
	TyPageDescription1: "In der obigen Tabelle sehen Sie alle Details zu Ihrer aktuellen Konfiguration, für die Sie kurzfristig ein unverbindliches Angebot erhalten.",
	TyPageDescription2: "Einer unserer Sachbearbeiter wird sich in Kürze mit Ihnen in Verbindung setzen, um die nächsten Schritte zu besprechen, z. B. Konfigurationsänderungen, Wandöffnungsmaße und die endgültige Bestellung.",
	model: "Modell",
	confHandleOutsideExtraColoring: "Zusätzliche Farben",
	confHandleOutsideColor: "Grifffarbe",
	confHandleOutsideAdditionalColor: "Muldenfarbe",
	confColorsOutside: "Außenfarben",
	confColorsInside: "Innenfarben",
	TyPageCopyRights: "All rights reserved 2023",
	logoLink: "https://www.inotherm-tuer.de/",
	InoWebsite: "Zu unserer Webseite",
	positionHouseNum: "Position der Hausnummer an der Tü",
	positionKnocker: "Position des Türklopfers",
	positionPeephole: "Position des Türspions ",
	positionInfo: "Cursor benutzen um das Zubehörteil an der gewünschten Stelle zu positionieren.",
	positionHorizontal: "HORIZONTAL",
	positionVertical: "VERTIKAL",
	headerFilterVsi: "Alle Modelle",
	headerFilterAkcija24: "Trend 24",
	headerFilterZimska: "Winteraktion",
	headerFilterTop: "Top Modelle",
	headerFilterAkcija: "Black Edition",
	openFilters: "FILTER",
	clearFilters: "Filter löschen",
	useFilters: "Ergebnisse anzeigen",
	bannerText: "WÄHLEN SIE EIN TÜRMODELL UND BEGINNEN SIE MIT DER GESTALTUNG IHRES EINGANGS",
	miniCokel: "Niedriges Sockelprofil",
	monthlyTopModel: "Tür des Monats",
	blackEditionTitle: "Black Edition",
	blackEditionDesc: "Verleihen Sie dem Design Ihrer Traumtür zeitlose Eleganz in Schwarz.",
	blackEditionAdd: "HINZUFÜGEN",
	blackEditionRemove: "ENTFERNEN",
}
