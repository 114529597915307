import { Routes, Route } from 'react-router-dom';
import DoorConfiguration from './components/Konfigurator';
import Inquiry from './components/Povprasevanje';
import sestavLoaded from './components/konfiguratorComponents/LoadSestav';
import ThankYouPage from './components/ZahvalnaStran';
import ConfigurationSummary from './components/povprasevanjeComponents/PovzetekKonfiguracije';
import CustomerInfo from './components/povprasevanjeComponents/PodatkiStranke';
import InoAPIclient from './api/InoAPIclient';
import { Context } from 'shared-home/src/home/Context';
import { HomePageWrapper } from './components/HomePageWrapper';
import useForm from 'shared-home/src/home/useForm';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

//PROMISE ZA SETTANJE OBJEKTA NA SESTAVU
const inoPromise = (setter, value) => {
  return new Promise(function (resolve, reject) {
    if (value) {
      window.inoAPI.getSestav()[setter](JSON.stringify(value), (e, r) => {
        if (e) {
          resolve(e);
        } else {
          reject(r);
        }
      });
    } else {
      resolve();
    }
  });
};

//NASTAVITEV PROMISE ZA BARVE
const inoPromiseBarva = (setter, value, bool) => {
  return new Promise(function (resolve, reject) {
    if (value) {
      if (bool === undefined) {
        window.inoAPI
          .getSestav()
          .getBarveUtil()
          [setter](JSON.stringify(value), (success, error) => {
            if (success) {
              resolve(success);
            } else {
              reject(error);
            }
          });
      } else {
        window.inoAPI
          .getSestav()
          .getBarveUtil()
          [setter](JSON.stringify(value), bool, (success, error) => {
            if (success) {
              resolve(success);
            } else {
              reject(error);
            }
          });
      }
    } else {
      resolve();
    }
  });
};

//NASTAVITEV PROMIS BREZ JSONa
const inoPromiseNoJSON = (setter, value) => {
  return new Promise(function (resolve, reject) {
    if (value) {
      window.inoAPI.getSestav()[setter](value, (success, error) => {
        if (success) {
          resolve(success);
        } else {
          reject(error);
        }
      });
    } else {
      resolve();
    }
  });
};

//NASTAVITEV PROMISE BREZ PARAMETROV
const inoPromiseNoParam = (setter) => {
  return new Promise(function (resolve, reject) {
    window.inoAPI.getSestav()[setter]((success, error) => {
      if (success) {
        resolve(success);
      } else {
        reject(error);
      }
    });
  });
};

//NASTAVITEV FASADE PROMISE
const inoPromiseFasada = (setter, value) => {
  return new Promise(function (resolve, reject) {
    if (value) {
      window.inoAPI.getFasada()[setter](JSON.stringify(value), (success, error) => {
        if (success) {
          resolve(success);
        } else {
          reject(error);
        }
      });
    } else {
      resolve();
    }
  });
};

function App() {
  const [catalogue, setCatalogue] = useState({});
  const [doorSvg, setDoorSvg] = useState();
  const [gwtReady, setGwtReady] = useState(false);
  const { form, setForm, onInputChange, onComponentChange, onCancelClick } = useForm({});
  const [, setInoInitialized] = useState(false);
  const formRef = useRef();
  const catalogueRef = useRef();
  const inoApiCall = new InoAPIclient();

  formRef.current = form;
  catalogueRef.current = catalogue;

  //FUNKCIJA ZA NASTAVLJANJE CATALOGOV
  function loadCatalogue(setter, element, cata) {
    return new Promise((resolve, reject) => {
      inoApiCall[setter](element).then((res) => {
        if (res) {
          setCatalogue((cat) => ({ ...cat, [cata]: res }));
          resolve(res);
        }
      });
    });
  }

  //FUNKCIJA ZA KLIC STEKEL OB SPREMEBI DIN-A
  function refreshSteklaPromise() {
    return new Promise(function (resolve, reject) {
      const sestav = window.inoAPI.getSestav();
      // const steklo = JSON.parse(sestav.getMotiv())
      const motivDTO = JSON.parse(sestav.getSteklaUtil().getMotivRequestDTO());
      const stekloModelaOrn = JSON.parse(sestav.getSteklaUtil().getSteklaRequestDTO());
      // console.log("getMotivRequestDTO: ",motivDTO, "getSteklaRequestDTO:",stekloModelaOrn)

      if (motivDTO === null) {
        onComponentChange({
          motivType: undefined
        });
      }

      if (stekloModelaOrn.steviloZasteklitev === 0) {
        onComponentChange({
          glassType: undefined
        });
      }

      const motivKonstrukcije = JSON.parse(sestav.getMotiv());
      if (motivKonstrukcije.id.id !== -1) {
        onComponentChange({
          motivType: motivKonstrukcije.id.id
        });
      }
      //load STEKLA ORNAMENTNA catalogue (depends on modelId) ce imajo vrata steklo
      if (stekloModelaOrn.steviloZasteklitev > 0) {
        onComponentChange({ doorHasGlass: true }); //FOR HEADER SCROLL
        loadCatalogue('steklaOrnamentna', stekloModelaOrn, 'steklaOrn');
      } else {
        setCatalogue((cat) => ({ ...cat, steklaOrn: undefined, steklaMot: undefined }));
        onComponentChange({ doorHasGlass: false }); //FOR HEADER SCROLL
      }

      const din = JSON.parse(sestav.getDin());
      // ISKANJE MOTIVOV
      if (sestav.getSteklaUtil().isKriloWithSteklo()) {
        loadCatalogue('steklaZasteklitev', motivDTO, 'steklaMot');
      }
      //OBSVETLOBA LEVO & DESNO
      else if (din.hasLeftPanel) {
        loadCatalogue('steklaOsvetloba', motivDTO, 'steklaMot');
      } else if (din.hasRightPanel) {
        loadCatalogue('steklaOsvetloba', motivDTO, 'steklaMot');
      }
      //NADSVETLOBA
      else if (din.hasTopPanel) {
        loadCatalogue('steklaNadsvetloba', motivDTO, 'steklaMot');
      }
      resolve();
    });
  }

  //FUNKCIJA ZA KLIC MOTIVNIH STEKEL OB MENJAVI DIMENZIJ
  function refreshSteklaDimensionPromise() {
    return new Promise(function (resolve, reject) {
      const sestav = window.inoAPI.getSestav();
      const din = JSON.parse(sestav.getDin());
      const motivDTO = JSON.parse(sestav.getSteklaUtil().getMotivRequestDTO());

      if (sestav.hasMenjave) {
        // ISKANJE MOTIVOV
        if (sestav.getSteklaUtil().isKriloWithSteklo()) {
          loadCatalogue('steklaZasteklitev', motivDTO, 'steklaMot');
        }
        //OBSVETLOBA LEVO & DESNO
        else if (din.hasLeftPanel) {
          loadCatalogue('steklaOsvetloba', motivDTO, 'steklaMot');
        } else if (din.hasRightPanel) {
          loadCatalogue('steklaOsvetloba', motivDTO, 'steklaMot');
        }
        //NADSVETLOBA
        else if (din.hasTopPanel) {
          loadCatalogue('steklaNadsvetloba', motivDTO, 'steklaMot');
        }
      }
      resolve();
    });
  }

  function refreshDrzalaPromise() {
    return new Promise(function (resolve, reject) {
      const sestav = window.inoAPI.getSestav();
      const kljukaZunSestav = JSON.parse(sestav.getZunanjaKljukaRequestDTO());
      let kljuka = JSON.parse(window.inoAPI.getSestav().getZunanjaKljuka());
      onComponentChange({
        outsideHandle: kljuka.id
      });
      loadCatalogue('zunanjaDrzala', kljukaZunSestav, 'zunDrzala').then((res) => {
        let zunanjaDrzalaMap = mapifyDrzala(res);
        setCatalogue((cat) => ({ ...cat, zunanjaDrzalaMap }));
      });

      resolve();
    });
  }

  //FUNKCIJA ZA NASTAVLJANJE ZUNANJEGA DRZALA
  function drzalaPromise(drzalo, selectedDimenzije) {
    return new Promise(function (resolve, reject) {
      const sestav = window.inoAPI.getSestav();
      sestav.setZunanjaKljuka(JSON.stringify(drzalo), JSON.stringify(selectedDimenzije), (e, r) => {
        if (e) {
          if (r === 'CHANGED_KLJUCAVNICA') {
            let kljucavnica = JSON.parse(window.inoAPI.getSestav().getKljucavnica());
            console.log(kljucavnica);
            onComponentChange({
              lockingType: kljucavnica.id
            });
          }
          let kljuka = JSON.parse(window.inoAPI.getSestav().getZunanjaKljuka());
          onComponentChange({
            outsideHandle: kljuka.id,
            updateOutsideHandleColorsBlackEdition: Math.random()
          });
          resolve(e);
        } else {
          reject(r);
        }
      });
    });
  }

  //MAPIRAJ ČEZ DRŽALA
  function mapifyDrzala(zunDrzala) {
    let fletKljuke = {};
    if (zunDrzala && Object.entries(fletKljuke).length === 0) {
      zunDrzala.forEach((element) => {
        if (Object.keys(element.skupineByDolzina).length === 0) {
          let skupina = element;
          let skupineKljuk = element.skupine;
          skupineKljuk.forEach((kljuka) => {
            let kljukaBarve = kljuka.kljukeBarve.length > 0 ? kljuka.kljukeBarve : kljuka.kljuke;
            kljukaBarve.forEach((barva) => {
              //DODAMO 'id' DA SE OBNAŠA KOT STRING IN NAREDI FLET PRAVILNO
              let kljukaId;
              let vnos = [skupina, kljuka, barva];
              if (barva.kljuka) {
                kljukaId = barva.kljuka.id + 'id';
                fletKljuke[kljukaId] = vnos;
              } else {
                kljukaId = barva.id + 'id';
                fletKljuke[kljukaId] = vnos;
              }
            });
          });
        } else {
          let skupina = element;
          let skupineDimenzij = element.skupineByDolzina;
          for (let i in skupineDimenzij) {
            let dimenzija = skupineDimenzij[i];
            let kljukaBarve = dimenzija.kljuke;
            kljukaBarve.forEach((kl) => {
              let kljukaId;
              let vnos = [skupina, dimenzija, kl];
              if (kl.kljuka) {
                kljukaId = kl.kljuka.id + 'id';
                fletKljuke[kljukaId] = vnos;
              } else {
                kljukaId = kl.id + 'id';
                fletKljuke[kljukaId] = vnos;
              }
            });
          }
        }
      });
    }
    return fletKljuke;
  }

  //FUNKCIJA NAJDE KLJUKO IN JO NASTAVI NA PODLAGI ID-ja
  async function setOutsideHandle(handleId, zunDrzalaMap, handleColorsId) {
    let kljukaId = handleId + 'id';
    if (zunDrzalaMap) {
      let selectedPath = zunDrzalaMap[kljukaId];
      if (selectedPath) {
        let selectedSkupina = selectedPath[1];
        let selectedKljuka = selectedPath[2].kljuka ? selectedPath[2].kljuka : selectedPath[2];
        await drzalaPromise(selectedKljuka, selectedSkupina);
      }
    }
    let notranjeGarnitureShow = JSON.parse(window.inoAPI.getSestav().getZunanjaKljuka()).garnituraType;
    onComponentChange({
      notranjeGarnitureShow: notranjeGarnitureShow
    });
    if (!form.insideHandle) {
      let notranjeDrzalo = JSON.parse(window.inoAPI.getSestav().getNotranjaKljuka());
      onComponentChange({
        insideHandle: notranjeDrzalo.id
      });
    }
  }

  //NASTAVLJANJE IZBRANIH OBJEKTOV NA VRATA (IZRIS)
  const beforeComponentChange = (f) => {
    if (!window.inoAPI) return;
    var cons = true;
    var promises = [];
    var promisesSecondary = [];
    const loadSestav = window.inoAPI.getSestav();

    function refreshDimensions() {
      return new Promise(function (resolve, reject) {
        const din = JSON.parse(loadSestav.getDin());
        onComponentChange({
          dimensionHeightDefault: din.defaultHeight,
          dimensionWidthDefault: din.defaultWidth,
          dimensionHeight: din.defaultHeight,
          dimensionWidth: din.defaultWidth,
          dimensionQueryReset: form.dimensionQueryReset !== undefined ? !form.dimensionQueryReset : false
        });
        setDimensions(din.defaultHeight, 'setVisina');
        setDimensions(din.defaultWidth, 'setSirina');
        resolve();
      });
    }

    function setDimensions(value, setter) {
      value &&
        promises.push(
          new Promise(function (resolve, reject) {
            loadSestav[setter](value, (success, error) => {
              if (success) {
                if (setter === 'setVisina') {
                  onComponentChange({ heightDimensionWarning: undefined });
                }
                if (setter === 'setSirina') {
                  onComponentChange({ widthDimensionWarning: undefined });
                }
                resolve(success);
              } else if (error) {
                if (setter === 'setVisina') {
                  onComponentChange({ heightDimensionWarning: error });
                }
                if (setter === 'setSirina') {
                  onComponentChange({ widthDimensionWarning: error });
                }
                resolve();
              } else {
                reject();
              }
            });
          })
        );
    }

    function refreshOutisdeHandleColors() {
      return new Promise(function (resolve, reject) {
        let kl = JSON.parse(loadSestav.getZunanjaKljukaBarva1());
        let kl2 = JSON.parse(loadSestav.getZunanjaKljukaBarva2());

        let barveIdArray = [];

        if (kl) {
          promises.push(inoPromise('setZunanjaKljukaBarva1', kl));
          barveIdArray[0] = kl.composedId.replace(/[^a-zA-Z0-9]/g, '');
        }

        if (kl2) {
          promises.push(inoPromise('setZunanjaKljukaBarva2', kl2));
          barveIdArray[1] = kl2.composedId.replace(/[^a-zA-Z0-9]/g, '');
        }

        onComponentChange({
          outsideHandleCol1: kl,
          outsideHandleCol2: kl2,
          outsideHandleColorsId: barveIdArray
        });

        resolve();
      });
    }

    function refreshKljucavniceCat() {
      let dovoljeniDodatki = JSON.parse(window.inoAPI.getSestav().getDovoljeniDodatki());
      const kljukaTip = JSON.parse(loadSestav.getZunanjaKljuka()).kljukaType;
      inoApiCall.zaklepanjaMehanizem({ tipKljuke: kljukaTip, dovoljeniDodatki: dovoljeniDodatki }).then((res) => {
        if (res) {
          let kljucavniceNaDrzalu = JSON.parse(loadSestav.getKljucavniceNaDrzalu());
          let vsaZaklepanja = res.concat(kljucavniceNaDrzalu);
          setCatalogue((cat) => ({ ...cat, zaklepanja: vsaZaklepanja }));
        }
      });
    }

    function refreshDodatki() {
      if (!window.inoAPI.getSestav().isMiniCokelEnabled()) {
        setCatalogue((cat) => ({ ...cat, miniCokel: undefined }));
        _onComponentChange({
          miniCokel: undefined
        });
        form.defaults.miniCokel = null;
        // setSelectedItem(undefined, catalogue.tolkala, 'setTolkalo', 'deleteTolkalo');
      } else {
        inoApiCall.dodatekMiniCokel().then((res) => {
          if (res) {
            setCatalogue((cat) => ({ ...cat, miniCokel: res }));
          }
        });
      }

      let dovoljeniDodatki = JSON.parse(window.inoAPI.getSestav().getDovoljeniDodatki());
      form.defaults.dovoljeniDodatki = dovoljeniDodatki;

      if (!dovoljeniDodatki.kukala) {
        setCatalogue((cat) => ({ ...cat, kukala: undefined }));
        _onComponentChange({
          peephole: undefined
        });
        form.defaults.peephole = null;
        // setSelectedItem(undefined, catalogue.kukala, 'setKukalo', 'deleteKukalo');
      } else if (dovoljeniDodatki.kukala) {
        inoApiCall.dodatkiKukala(dovoljeniDodatki).then((res) => {
          if (res) {
            setCatalogue((cat) => ({ ...cat, kukala: res }));
          }
        });
      }

      if (!dovoljeniDodatki.tolkala) {
        setCatalogue((cat) => ({ ...cat, tolkala: undefined }));
        _onComponentChange({
          knocker: undefined
        });
        form.defaults.knocker = null;
        // setSelectedItem(undefined, catalogue.tolkala, 'setTolkalo', 'deleteTolkalo');
      } else if (dovoljeniDodatki.tolkala) {
        inoApiCall.dodatekTolkala(dovoljeniDodatki).then((res) => {
          if (res) {
            setCatalogue((cat) => ({ ...cat, tolkala: res }));
          }
        });
      }

      if (!dovoljeniDodatki.odkapnaLetev) {
        setCatalogue((cat) => ({ ...cat, odkapneLetve: undefined }));
        _onComponentChange({
          dripBatten: undefined
        });
        form.defaults.dripBatten = null;
        // setSelectedItem(undefined, catalogue.tolkala, 'setTolkalo', 'deleteTolkalo');
      } else if (dovoljeniDodatki.odkapnaLetev) {
        inoApiCall
          .dodatkiOdkapneLetve({
            dovoljeniDodatki,
            akcija2024: window.inoAPI.getSestav().isAkcija2024()
          })
          .then((res) => {
            if (res) {
              setCatalogue((cat) => ({ ...cat, odkapneLetve: res }));
            }
          });
      }

      if (!dovoljeniDodatki.hisneStevilke) {
        setCatalogue((cat) => ({ ...cat, hisneStevilke: undefined }));
        _onComponentChange({
          houseNumberColId: undefined,
          houseNumberDef: undefined,
          houseNumber: undefined
        });
        form.defaults.houseNumberDef = null;
        // setHouseNum(undefined, 'setHisnaStevilkaDirect', 'deleteHisnaStevilkaDirect')
      } else if (dovoljeniDodatki.hisneStevilke) {
        inoApiCall.dodatekHisneStevilke(dovoljeniDodatki).then((res) => {
          if (res) {
            setCatalogue((cat) => ({ ...cat, hisneStevilke: res }));
          }
        });
      }
    }

    function setColor(colors, setter, isInside) {
      colors &&
        colors.forEach((col) => {
          if (col) {
            promises.push(inoPromiseBarva(setter, col, isInside));
          }
        });
    }

    function setSelectedItem(item, cata, setter, deleteSetter, motivna) {
      let selectedItem = item && cata && cata.find((e) => (motivna ? e.id.id : e.id) === item); //id od motivnega stekla se nahaja na 'e.id.id' zato damo pogoj za motivna
      if (selectedItem) {
        promises.push(inoPromise(setter, selectedItem));
      } else if (deleteSetter) {
        promises.push(inoPromiseNoParam(deleteSetter));
      }
    }

    function setSelectedDodatekPosition(item, position, cata, setter, deleteSetter) {
      const selectedItem = item && cata && cata.find((e) => e.id == item); //id od motivnega stekla se nahaja na 'e.id.id' zato damo pogoj za motivna
      if (selectedItem) {
        const cloned = structuredClone(selectedItem);
        if (position) {
          cloned.pozicija.x = position[0];
          cloned.pozicija.y = position[1];
        }
        promises.push(inoPromise(setter, cloned));
        return cloned;
      } else if (deleteSetter) {
        promises.push(inoPromiseNoParam(deleteSetter));
        return null;
      }
    }

    function setHouseNum(number, position, setter, deleteSetter) {
      if (number) {
        const cloned = structuredClone(number);
        if (position) {
          cloned.pozicija.x = position[0];
          cloned.pozicija.y = position[1];
        }
        promises.push(inoPromise(setter, cloned));
      } else {
        promises.push(inoPromiseNoParam(deleteSetter));
      }
    }

    const entries = Object.entries(f);
    for (const [key, value] of entries) {
      if (key === 'doorViewInside') {
        promises.push(() => Promise.resolve()); //to trigger refresh
      } else if (key === 'doorOpeningLeftSide') {
        value && promises.push(inoPromiseNoParam('setOdpiranjeLevo'));
      } else if (key === 'doorOpeningRightSide') {
        value && promises.push(inoPromiseNoParam('setOdpiranjeDesno'));
      } else if (key === 'constructionType' && cons) {
        value && promises.push(inoPromiseNoJSON('setDinId', value));
        promisesSecondary.push(refreshSteklaPromise); //do not trigger, no "()"
        promisesSecondary.push(refreshDimensions); //do not trigger, no "()"
        //        promisesSecondary.push(steklaDinUpdate);
        promisesSecondary.push(refreshDrzalaPromise);
        promisesSecondary.push(refreshDodatki);
      } else if (key === 'blackEditionState') {
        promises.push(
          new Promise(function (resolve, reject) {
            window.inoAPI.getSestav().setBlackEditionState(value, (e, r) => {
              if (e) {
                resolve(e);
              } else {
                reject(r);
              }
            });
          })
        );
      }

      //DIMENZIJE
      else if (key === 'dimensionHeight') {
        setDimensions(value, 'setVisina');
        promisesSecondary.push(refreshSteklaDimensionPromise);
        promisesSecondary.push(refreshDrzalaPromise);
        promisesSecondary.push(refreshDodatki);
      } else if (key === 'dimensionWidth') {
        setDimensions(value, 'setSirina');
        promisesSecondary.push(refreshSteklaDimensionPromise);
        promisesSecondary.push(refreshDrzalaPromise);
        promisesSecondary.push(refreshDodatki);
      }

      //BARVE
      else if (key === 'colorsMain') {
        setColor(value, 'setAggregatedBarva');
      } else if (key === 'colorWingOutside') {
        setColor(value, 'setBarvanLayer', false);
        promisesSecondary.push(refreshOutisdeHandleColors);
      } else if (key === 'colorFrameOutside') {
        setColor(value, 'setBarvanLayer', false);
      } else if (key === 'colorAplikacijeOutside') {
        setColor(value, 'setBarvanLayer', false);
      } else if (key === 'colorDekorOutside') {
        setColor(value, 'setBarvanLayer', false);
      } else if (key === 'colorOkvirckiOutside') {
        setColor(value, 'setBarvanLayer', false);
      } else if (key === 'colorWingInside') {
        setColor(value, 'setBarvanLayer', true);
      } else if (key === 'colorFrameInside') {
        setColor(value, 'setBarvanLayer', true);
        promises.push(inoPromiseNoParam('correctPantiColor'));
      } else if (key === 'colorAplikacijeInside') {
        setColor(value, 'setBarvanLayer', true);
      } else if (key === 'colorDekorInside') {
        setColor(value, 'setBarvanLayer', true);
      } else if (key === 'colorOkvirckiInside') {
        setColor(value, 'setBarvanLayer', true);
      }
      //STEKLA
      else if (key === 'motivType') {
        setSelectedItem(value, catalogue.steklaMot, 'setMotiv', null, true);
      } else if (key === 'glassType') {
        setSelectedItem(value, catalogue.steklaOrn, 'setSteklo');
      }

      //DRZALA
      else if (key === 'outsideHandle') {
        promises.push(setOutsideHandle(value, catalogue.zunanjaDrzalaMap));
        promisesSecondary.push(refreshOutisdeHandleColors);
        promisesSecondary.push(refreshKljucavniceCat);
      } else if (key === 'outsideHandleCol1') {
        value && promises.push(inoPromise('setZunanjaKljukaBarva1', value));
      } else if (key === 'outsideHandleCol2') {
        value && promises.push(inoPromise('setZunanjaKljukaBarva2', value));
      } else if (key === 'insideHandle') {
        setSelectedItem(value, catalogue.notrDrzala, 'setNotranjaKljuka');
      }

      //ZAKLEPANJE
      else if (key === 'lockingType') {
        promises.push(
          new Promise(function (resolve, reject) {
            if (value) {
              let selectedItem = value && catalogue.zaklepanja && catalogue.zaklepanja.find((e) => e.id === value);
              if (selectedItem) {
                promises.push(
                  loadSestav.setKljucavnica(JSON.stringify(selectedItem), (e, r) => {
                    if (e) {
                      if (r) {
                        let kljuka = JSON.parse(loadSestav.getZunanjaKljuka());
                        onComponentChange({
                          outsideHandle: kljuka.id
                        });
                      }
                      resolve(e);
                    } else {
                      reject(r);
                    }
                  })
                );
              }
            } else {
              resolve();
            }
          })
        );
        promisesSecondary.push(refreshOutisdeHandleColors);
        // setSelectedItem(value, catalogue.zaklepanja, 'setKljucavnica')
      }

      //DODATKI
      else if (key === 'miniCokel') {
        setSelectedItem(value, catalogue.miniCokel, 'setMiniCokel', 'deleteMiniCokel');
      } else if (key === 'dripBatten') {
        setSelectedItem(value, catalogue.odkapneLetve, 'setOdkapnaLetev', 'deleteOdkapnaLetev');
      } else if (key === 'houseNumber') {
        value && setHouseNum(form.houseNumberDef, f.houseNumberPosition, 'setHisnaStevilkaDirect', 'deleteHisnaStevilkaDirect');
      } else if (key === 'houseNumberDef') {
        setHouseNum(value, f.houseNumberPosition, 'setHisnaStevilkaDirect', 'deleteHisnaStevilkaDirect');
      } else if (key === 'peephole') {
        setSelectedDodatekPosition(value, f.peepholePosition, catalogue.kukala, 'setKukalo', 'deleteKukalo');
      } else if (key === 'knocker') {
        const knockerValue = setSelectedDodatekPosition(value, f.knockerPosition, catalogue.tolkala, 'setTolkalo', 'deleteTolkalo');
        f.knockerValue = knockerValue;
      }
      // BACKGROUND IMAGE
      else if (key === 'BGPicture') {
        promises.push(inoPromiseFasada('setSelectedFasada', value));
        promises.push(inoPromiseFasada('setFasadaUrl', value));
      } else if (key === 'bgPictureURL') {
        promises.push(inoPromiseFasada('setFasadaUrl', value));
      } else if (key === 'bgPictureFasadaId') {
        promises.push(inoPromiseFasada('setSelectedFasada', value));
      }
    }

    if (promises.length > 0) {
      Promise.all(promises)
        .then(() => Promise.all(promisesSecondary.map((fun) => fun())))
        .then(() => {
          const din = JSON.parse(loadSestav.getDin());
          var svg;
          if ('doorViewInside' in f ? f.doorViewInside : form.doorViewInside) {
            svg = window.inoAPI.getDoorSketcher().getSVGNotranji();
          } else {
            svg = window.inoAPI.getDoorSketcher().getSVG();
          }
          setDoorSvg(svg);
          onComponentChange({
            constructionType: din.id
          });
        });
    }
  };

  const _onComponentChange = useCallback(
    (f) => {
      beforeComponentChange(f);
      onComponentChange(f);
    },
    [onComponentChange, beforeComponentChange]
  );

  const _onInputChange = useCallback(
    (e) => {
      const name = e.target.name;
      const value = e.currentTarget.type === 'checkbox' ? e.target.checked : e.target.value;

      if (name) {
        _onComponentChange({ [name]: value });
      } else {
        onInputChange(e);
      }
    },
    [_onComponentChange, onInputChange]
  );

  useEffect(() => {
    document.addEventListener('gwtReady', function (e) {
      console.log('gwtReady');
      window.inoAPI = window.newInoAPI();
      document.title = window.inoAPI.getPageTitle();
      document.querySelector('meta[name="description"]').setAttribute('content', window.inoAPI.getPageMetaDesc());
      document.querySelector('meta[property="og:title"]').setAttribute('content', window.inoAPI.getPageMetaDesc());
      console.log('gwtReady');
      setGwtReady(true);
    });
  }, []);

  useEffect(() => {
    initializeInoAPI(form.modelId ? form.modelId : null, form.modelName, form.modelCode ? form.modelCode : null);
  }, [gwtReady]);

  function fillCatalogue(setter, catalogue, element) {
    inoApiCall[setter](element).then((res) => {
      if (res) {
        setCatalogue((cat) => ({ ...cat, [catalogue]: res }));
      }
    });
  }

  const initializeInoAPI = (modelId, modelName, sifraModel, onSestavLoaded) => {
    const formLocal = formRef.current;
    const catalogue = catalogueRef.current;
    // const modelId = newModelId ? newModelId : form.modelId;
    console.log('initialize', formLocal);

    if (modelId || modelName || sifraModel) {
      if (window.dataLayer) {
        try {
          window.dataLayer.push({
            event: 'filter_configuration',
            filter_door_model: modelName,
            filter_door_programme: formLocal.izberiModel,
            filter_door_model_type: formLocal.tipModela,
            filter_door_glass_amount: formLocal.steklo
          });
          console.log('google tag manager dataLayer push filter_configuration', modelName, formLocal.izberiModel, formLocal.tipModela, formLocal.steklo);
        } catch (e) {
          console.log('google tag manager dataLayer push error', e);
        }
      }
      window.inoAPI.loadSestav(modelId, formLocal.constructionType ? formLocal.constructionType : 1, formLocal.dimensionWidth ? formLocal.dimensionWidth : null, formLocal.dimensionHeight ? formLocal.dimensionHeight : null, modelName ? modelName : null, sifraModel, (resolved, error) => {
        if (resolved) {
          sestavLoaded(resolved, error, setForm, formLocal, onComponentChange, catalogue, setCatalogue, loadCatalogue, mapifyDrzala, inoApiCall, setDoorSvg);
          console.log('Sestav loaded');
          onSestavLoaded && onSestavLoaded();
        } else {
          console.log('Error Callback', error);
        }
      });
    }
    setInoInitialized(true); //to force rerender
  };

  return (
    <Suspense fallback="Loading...">
      <Context.Provider
        value={{
          form,
          setForm,
          onInputChange: _onInputChange,
          onComponentChange: _onComponentChange,
          onCancelClick,
          catalogue,
          setCatalogue,
          doorSvg,
          setDoorSvg,
          initializeInoAPI,
          gwtReady,
          setGwtReady
        }}>
        <Routes>
          <Route path="#" element={<HomePageWrapper />} />
          <Route path="/" element={<HomePageWrapper />} />
          <Route path="home" element={<HomePageWrapper />} />
          <Route path={'/home/:izberiModel/:tipModela/:steklo/:oblikaStekla/:iskanje'} element={<HomePageWrapper />} />
          <Route path={'/home/:izberiModel/:tipModela/:steklo/:oblikaStekla/:iskanje/:directFilters'} element={<HomePageWrapper />} />
          <Route path="inquiry" element={<Inquiry />} />
          <Route path="inquiryConfigurator" element={<ConfigurationSummary />} />
          <Route path="inquiryCustomerInfo" element={<CustomerInfo />} />
          <Route path="thankyou" element={<ThankYouPage />} />
          <Route path="/:modelName" element={<DoorConfiguration />} />
          <Route path="/modelCode/:modelCode" element={<DoorConfiguration />} />
          <Route path={'/configurator/:modelId/:modelName/:doorOpeningLeftSide/:doorOpeningRightSide/:constructionType/:dimensionHeight/:dimensionWidth/:colorWingOutsideId/:colorFrameOutsideId/:colorAplikacijeOutsideId/:colorDekorOutsideId/:colorOkvirckiOutsideId/:colorWingInsideId/:colorFrameInsideId/:colorAplikacijeInsideId/:colorDekorInsideId/:colorOkvirckiInsideId/:motivType/:glassType/:outsideHandle/:outsideHandleColorsId/:insideHandle/:lockingType/:dripBatten/:houseNumberColId/:houseNumber/:peephole/:knocker'} element={<DoorConfiguration />} />
          <Route path={'/configurator/:modelId/:modelName/:doorOpeningLeftSide/:doorOpeningRightSide/:constructionType/:dimensionHeight/:dimensionWidth/:colorWingOutsideId/:colorFrameOutsideId/:colorAplikacijeOutsideId/:colorDekorOutsideId/:colorOkvirckiOutsideId/:colorWingInsideId/:colorFrameInsideId/:colorAplikacijeInsideId/:colorDekorInsideId/:colorOkvirckiInsideId/:motivType/:glassType/:outsideHandle/:outsideHandleColorsId/:insideHandle/:lockingType/:dripBatten/:houseNumberColId/:houseNumber/:peephole/:knocker/:houseNumberPosition/:peepholePosition/:knockerPosition'} element={<DoorConfiguration />} />
          <Route path={'/configurator/:modelId/:modelName/:doorOpeningLeftSide/:doorOpeningRightSide/:constructionType/:dimensionHeight/:dimensionWidth/:colorWingOutsideId/:colorFrameOutsideId/:colorAplikacijeOutsideId/:colorDekorOutsideId/:colorOkvirckiOutsideId/:colorWingInsideId/:colorFrameInsideId/:colorAplikacijeInsideId/:colorDekorInsideId/:colorOkvirckiInsideId/:motivType/:glassType/:outsideHandle/:outsideHandleColorsId/:insideHandle/:lockingType/:dripBatten/:houseNumberColId/:houseNumber/:peephole/:knocker/:houseNumberPosition/:peepholePosition/:knockerPosition/:miniCokel'} element={<DoorConfiguration />} />
          <Route path={'/configurator/:modelId/:modelName/:doorOpeningLeftSide/:doorOpeningRightSide/:constructionType/:dimensionHeight/:dimensionWidth/:colorWingOutsideId/:colorFrameOutsideId/:colorAplikacijeOutsideId/:colorDekorOutsideId/:colorOkvirckiOutsideId/:colorWingInsideId/:colorFrameInsideId/:colorAplikacijeInsideId/:colorDekorInsideId/:colorOkvirckiInsideId/:motivType/:glassType/:outsideHandle/:outsideHandleColorsId/:insideHandle/:lockingType/:dripBatten/:houseNumberColId/:houseNumber/:peephole/:knocker/:houseNumberPosition/:peepholePosition/:knockerPosition/:miniCokel/:blackEditionState'} element={<DoorConfiguration />} />
        </Routes>
      </Context.Provider>
    </Suspense>
  );
}

export default App;
