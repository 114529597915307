import cloneDeep from 'lodash/cloneDeep';

const sestavLoaded = (resolved, error, setForm, form, onComponentChange, catalogue, setCatalogue, loadCatalogue, mapifyDrzala, inoApiCall, setDoorSvg) => {
  onComponentChange({ sestavLoaded: true });

  var svg;
  const sestav = window.inoAPI.getSestav();

  const barveSestav = JSON.parse(window.inoAPI.getSestav().getBarveUtil().getBarve());

  var stage = JSON.parse(window.inoAPI.getSestav().getSestavFrontend(false));
  //    var stageTrue = JSON.parse(window.inoAPI.getSestav().getSestavFrontend(true));
  // console.log("getSestavFrontend FALSE: ", { ...stage });
  // console.log("getSestavFrontend DEFAULTS: ", stageTrue);

  //PROMISE ZA SETTANJE OBJEKTA NA SESTAVU
  const inoStage = (setter, value) => {
    if (value) {
      stage[setter] = value;
    }
  };

  //NASTAVITEV PROMISE ZA BARVE
  const inoStageBarva = (value, isInside) => {
    if (value) {
      const side = isInside ? 'notranje' : 'zunanje';

      if (!stage.barve) {
        stage.barve = {};
      }
      if (!stage.barve[side]) {
        stage.barve[side] = {};
      }
      if (!stage.barve[side].aplikacije) {
        stage.barve[side].aplikacije = [];
      }
      stage.barve[side].aplikacije.push(value);
    }
  };

  //FUNKCIJA ZA NASTAVLJANJE ZUNANJEGA DRZALA
  function inoStageDrzala(drzalo, selectedDimenzije) {
    if (drzalo) {
      stage.zunanjaKljuka = drzalo;
      stage.kljukaSkupina = selectedDimenzije;
      stage.zunanjaKljukaBarva1 = drzalo.barvaKljuke;
      stage.zunanjaKljukaBarva2 = drzalo.barvaKljuke2;
    }
  }

  //FUNKCIJA NAJDE KLJUKO IN JO NASTAVI NA PODLAGI ID-ja
  async function setOutsideHandle(handleId, zunDrzalaMap, handleColorsId) {
    let kljukaId = handleId + 'id';
    if (zunDrzalaMap) {
      let selectedPath = zunDrzalaMap[kljukaId];
      if (selectedPath) {
        let selectedSkupina = selectedPath[1];
        let selectedKljuka = selectedPath[2].kljuka ? selectedPath[2].kljuka : selectedPath[2];
        inoStageDrzala(selectedKljuka, selectedSkupina);
      }
    }
    let notranjeGarnitureShow = JSON.parse(window.inoAPI.getSestav().getZunanjaKljuka()).garnituraType;
    onComponentChange({
      notranjeGarnitureShow: notranjeGarnitureShow
    });
    if (!form.insideHandle) {
      let notranjeDrzalo = JSON.parse(window.inoAPI.getSestav().getNotranjaKljuka());
      onComponentChange({
        insideHandle: notranjeDrzalo.id
      });
    }
  }

  sestav.getDin(); //workaround for SASO BUG: the defaultHeight&defaultWidth are null for the first call
  const dinObj = JSON.parse(sestav.getDin());

  function getDodatekPosition(jsonValue) {
    const json = JSON.parse(jsonValue);
    if (json.id > 0) {
      return [json.pozicija.x, json.pozicija.y];
    } else {
      return null;
    }
  }

  const modelName = sestav.getModelName();
  const modelId = sestav.getModelId();

  const akcija2024 = sestav.isAkcija2024();
  //GET DEFAULT MODEL VALUES
  const defaults = {
    modelName: modelName,
    modelId: modelId,
    constructionType: 1,
    dovoljeniDodatki: JSON.parse(sestav.getDovoljeniDodatki()),
    knocker: JSON.parse(sestav.getTolkalo()).id,
    miniCokel: JSON.parse(sestav.getMiniCokel()) !== null ? JSON.parse(sestav.getMiniCokel()).id : JSON.parse(sestav.getMiniCokel()),
    knockerPosition: getDodatekPosition(sestav.getTolkalo()),
    peephole: JSON.parse(sestav.getKukalo()).id,
    peepholePosition: getDodatekPosition(sestav.getKukalo()),
    dripBatten: JSON.parse(sestav.getOdkapnaLetev()) !== null ? JSON.parse(sestav.getOdkapnaLetev()).id : JSON.parse(sestav.getOdkapnaLetev()),
    lockingType: JSON.parse(sestav.getKljucavnica()).id,
    insideHandle: JSON.parse(sestav.getNotranjaKljuka()).id,
    outsideHandle: JSON.parse(sestav.getZunanjaKljuka()).id,
    houseNumberIMG: JSON.parse(sestav.getHisnaStevilka()),
    houseNumberColId: JSON.parse(sestav.getHisnaStevilkaDirect()).id,
    houseNumberDef: JSON.parse(sestav.getHisnaStevilkaDirect()),
    houseNumberPosition: getDodatekPosition(sestav.getHisnaStevilkaDirect()),
    blackEditionState: sestav.isBlackEditionState(),
    glassType: JSON.parse(sestav.getSteklo()).id,
    motivType: JSON.parse(sestav.getMotiv()).id.id,
    doorOpeningLeftSide: JSON.parse(sestav.isOdpiranjeLevo()),
    doorOpeningRightSide: JSON.parse(sestav.isOdpiranjeDesno()),
    //default dimensions from DIN - ConstructionType
    dimensionHeightDefault: dinObj.defaultHeight,
    dimensionWidthDefault: dinObj.defaultWidth,
    //default dimensions from door MODEL
    // dimensionHeight: JSON.parse(sestav.getVisina()),
    // dimensionWidth: JSON.parse(sestav.getSirina()),

    colorsModel: barveSestav,
    colorsMain: barveSestav.aggregatedBarva ? barveSestav.aggregatedBarva : null,
    colorWingInside: barveSestav.notranje.krilo ? barveSestav.notranje.krilo : null,
    colorFrameInside: barveSestav.notranje.okvir ? barveSestav.notranje.okvir : null,
    colorAplikacijeInside: barveSestav.notranje.aplikacije ? barveSestav.notranje.aplikacije : null,
    colorDekorInside: barveSestav.notranje.dekor ? barveSestav.notranje.dekor : null,
    colorOkvirckiInside: barveSestav.notranje.okvircki ? barveSestav.notranje.okvircki : null,
    colorWingOutside: barveSestav.zunanje.krilo ? barveSestav.zunanje.krilo : null,
    colorFrameOutside: barveSestav.zunanje.okvir ? barveSestav.zunanje.okvir : null,
    colorAplikacijeOutside: barveSestav.zunanje.aplikacije ? barveSestav.zunanje.aplikacije : null,
    colorDekorOutside: barveSestav.zunanje.dekor ? barveSestav.zunanje.dekor : null,
    colorOkvirckiOutside: barveSestav.zunanje.okvircki ? barveSestav.zunanje.okvircki : null
  };
  setForm((f) => ({ ...defaults, ...f, defaults: defaults }));
  var promises = [];

  function fromUrlCheck(element, catalogue, setter) {
    if (form[element] && form[element] !== defaults[element]) {
      let selectedElement = form[element] && catalogue && catalogue.find((e) => e.id === form[element]);
      if (selectedElement) {
        inoStage(setter, selectedElement);
      }
    }
  }
  function fromUrlCheckWithPosition(element, position, catalogue, setter) {
    if (form[element] && form[element] !== defaults[element]) {
      let selectedElement = form[element] && catalogue && catalogue.find((e) => e.id === form[element]);
      if (selectedElement) {
        const cloned = cloneDeep(selectedElement);
        const positionValue = form[position];
        if (positionValue) {
          cloned.pozicija.x = positionValue[0];
          cloned.pozicija.y = positionValue[1];
        }
        inoStage(setter, cloned);
      }
    }
  }

  //KUKALA IZ LINKA CHECK
  inoApiCall.dodatkiOdkapneLetve({ dovoljeniDodatki: defaults.dovoljeniDodatki, akcija2024 }).then((res) => {
    if (res) {
      setCatalogue((cat) => ({ ...cat, odkapneLetve: res }));
      fromUrlCheck('dripBatten', res, 'odkapnaLetev');
    }
  });

  //ZAKLEPANJE IZ LINKA CHECK
  inoApiCall.zaklepanjaMehanizem({ tipKljuke: 1, dovoljeniDodatki: defaults.dovoljeniDodatki }).then((res) => {
    if (res) {
      fromUrlCheck('lockingType', res, 'kljucavnica');
    }
  });

  //KUKALA IZ LINKA CHECK
  inoApiCall.dodatkiKukala(defaults.dovoljeniDodatki).then((res) => {
    if (res) {
      setCatalogue((cat) => ({ ...cat, kukala: res }));
      fromUrlCheckWithPosition('peephole', 'peepholePosition', res, 'kukalo');
    }
  });
  //KUKALA IZ LINKA CHECK
  inoApiCall.dodatkiOdkapneLetve({ dovoljeniDodatki: defaults.dovoljeniDodatki, akcija2024 }).then((res) => {
    if (res) {
      setCatalogue((cat) => ({ ...cat, odkapneLetve: res }));
      fromUrlCheck('dripBatten', res, 'odkapnaLetev');
    }
  });

  inoApiCall.dodatekTolkala(defaults.dovoljeniDodatki).then((res) => {
    if (res) {
      setCatalogue((cat) => ({ ...cat, tolkala: res }));
      fromUrlCheckWithPosition('knocker', 'knockerPosition', res, 'tolkalo');
    }
  });

  inoApiCall.dodatekMiniCokel().then((res) => {
    if (res) {
      setCatalogue((cat) => ({ ...cat, miniCokel: res }));
      fromUrlCheck('miniCokel', res, 'miniCokel');
    }
  });

  inoApiCall.dodatekHisneStevilke(defaults.dovoljeniDodatki).then((res) => {
    if (res) {
      setCatalogue((cat) => ({ ...cat, hisneStevilke: res }));

      if (form.houseNumberColId !== defaults.houseNumberDef.id) {
        if (form.houseNumber) {
          res.forEach((hisnaColor) => {
            if (form.houseNumberColId === hisnaColor.id) {
              hisnaColor.napis = form.houseNumber;
              const cloned = cloneDeep(hisnaColor);
              const positionValue = form['houseNumberPosition'];
              if (positionValue) {
                cloned.pozicija.x = positionValue[0];
                cloned.pozicija.y = positionValue[1];
              }
              inoStage('hisnaStevilkaDirect', cloned);
            }
          });
        }
      } else {
        onComponentChange({ houseNumberColId: null });
      }
    }
  });

  //HIŠNE ŠTEVILKE IZ LINKA CHECK
  // if (form.houseNumberColId !== defaults.houseNumberDef.id) {
  //     if (form.houseNumber) {
  //         catalogue.hisneStevilke.forEach(hisnaColor => {
  //             if (form.houseNumberColId === hisnaColor.id) {
  //                 hisnaColor.napis = form.houseNumber;
  //                 inoStage('hisnaStevilkaDirect', hisnaColor);
  //             }
  //         });
  //     } else {
  //         onComponentChange({ houseNumberColId: null });
  //     }
  // }

  //ZUNANJE KLJUKE IZ LINKA CHECK
  const kljukaZunSestav = JSON.parse(sestav.getZunanjaKljukaRequestDTO());
  let zunanjaDrzalaPromise = loadCatalogue('zunanjaDrzala', kljukaZunSestav, 'zunDrzala').then((res) => {
    let zunanjaDrzalaMap = mapifyDrzala(res);
    setCatalogue((cat) => ({ ...cat, zunanjaDrzalaMap }));
    return zunanjaDrzalaMap;
  });

  if (form.outsideHandle && form.outsideHandle !== defaults.outsideHandle) {
    promises.push(
      (async () => {
        let zunDrzalaMap = await zunanjaDrzalaPromise;
        await setOutsideHandle(form.outsideHandle, zunDrzalaMap, form.outsideHandleColorsId);
      })()
    );
  }

  //NOTRANJE KLJUKE IZ LINKA CHECK
  const serieSestav = sestav.getSerieSestav();
  const prospektPopust = sestav.getProspektPopust();
  const blackEdition = sestav.isBlackEdition();
  if (form.insideHandle && form.insideHandle !== defaults.insideHandle) {
    let kljukeArray;
    const data = { serieSestav, prospektPopust, blackEdition, akcija2024 };
    inoApiCall
      .notranjeKljuke(data)
      .then((res) => {
        //lahko podamo funkcijo za nastavljanje katalogov (getCatalogue)???
        if (res) {
          setCatalogue((cat) => ({ ...cat, notrDrzala: res }));
          kljukeArray = res;
        }
      })
      .then(() => {
        fromUrlCheck('insideHandle', kljukeArray, 'notranjaKljuka');
      });
  } else {
    //GET NOTRANJA DRŽALA
    loadCatalogue('notranjeKljuke', { serieSestav, prospektPopust, blackEdition, akcija2024 }, 'notrDrzala');
  }

  //STEKLA ORN IZ LINKA CHECK
  if (form.glassType && (form.glassType !== defaults.glassType || form.glassType === 272)) {
    const stekloModela = JSON.parse(sestav.getSteklaUtil().getSteklaRequestDTO());
    if (stekloModela.steviloZasteklitev > 0) {
      onComponentChange({ doorHasGlass: true });

      promises.push(
        new Promise(function (resolve, reject) {
          inoApiCall.steklaOrnamentna(JSON.parse(sestav.getSteklaUtil().getSteklaRequestDTO())).then((ornamentnaArray) => {
            if (ornamentnaArray) {
              setCatalogue((cat) => ({ ...cat, steklaOrn: ornamentnaArray }));
              let selectedOrnamentno = form.glassType && ornamentnaArray.find((e) => e.id === form.glassType);
              if (selectedOrnamentno) {
                inoStage('steklo', selectedOrnamentno);
                resolve();
              } else {
                reject('glassType not found in catalogue');
              }
            } else {
              reject('No catalogue: getSteklaRequestDTO');
            }
          });
        })
      );
    } else {
      onComponentChange({ doorHasGlass: false });
    }
    //če najdemo ornamentno nastavimo motivno na null
    onComponentChange({ motivType: null });
  }

  //STEKLA MOTIVNA IZ LINKA CHECK
  if (form.motivType && form.motivType !== defaults.motivType) {
    const din = JSON.parse(sestav.getDin());
    // ISKANJE MOTIVOV
    promises.push(
      new Promise(function (resolve, reject) {
        let kriloWithSteklo = sestav.getSteklaUtil().isKriloWithSteklo();
        if (kriloWithSteklo) {
          inoApiCall.steklaZasteklitev(JSON.parse(sestav.getSteklaUtil().getMotivRequestDTO())).then((motivnaArray) => {
            if (motivnaArray) {
              setCatalogue((cat) => ({ ...cat, steklaMot: motivnaArray }));
              let selectedMot = form.motivType && motivnaArray.find((e) => e.id.id === form.motivType);
              if (selectedMot) {
                inoStage('motiv', selectedMot);
                resolve();
              }
            }
          });
        }
        //OBSVETLOBA LEVO & DESNO
        else if (din.hasLeftPanel) {
          inoApiCall.steklaOsvetloba(JSON.parse(sestav.getSteklaUtil().getMotivRequestDTO())).then((motivnaArray) => {
            if (motivnaArray) {
              setCatalogue((cat) => ({ ...cat, steklaMot: motivnaArray }));
              let selectedMot = form.motivType && motivnaArray.find((e) => e.id.id === form.motivType);
              if (selectedMot) {
                inoStage('motiv', selectedMot);
                resolve();
              }
            }
          });
        } else if (din.hasRightPanel) {
          inoApiCall.steklaOsvetloba(JSON.parse(sestav.getSteklaUtil().getMotivRequestDTO())).then((motivnaArray) => {
            if (motivnaArray) {
              setCatalogue((cat) => ({ ...cat, steklaMot: motivnaArray }));
              let selectedMot = form.motivType && motivnaArray.find((e) => e.id.id === form.motivType);
              if (selectedMot) {
                inoStage('motiv', selectedMot);
                resolve();
              }
            }
          });
        }
        //NADSVETLOBA
        else if (din.hasTopPanel) {
          inoApiCall.steklaNadsvetloba(JSON.parse(sestav.getSteklaUtil().getMotivRequestDTO())).then((motivnaArray) => {
            if (motivnaArray) {
              setCatalogue((cat) => ({ ...cat, steklaMot: motivnaArray }));
              let selectedMot = form.motivType && motivnaArray.find((e) => e.id.id === form.motivType);
              if (selectedMot) {
                inoStage('motiv', selectedMot);
                resolve();
              }
            }
          });
        }
      })
    );
    //če motivno steklo najdemo nastavimo ornamentno na null
    onComponentChange({ glassType: null });
  }

  //Če je izbrano steklo defaultno motivno pobrisemo ornamento iz forme, če je izbrano ornamentno defaultno pobrišemo motivno
  if (form.motivType === defaults.motivType && (form.glassType === defaults.glassType || form.glassType === undefined)) {
    onComponentChange({ glassType: null });
  } else if (form.glassType === defaults.glassType && (form.motivType === defaults.motivType || form.motivType === undefined)) {
    onComponentChange({ motivType: null });
  }

  //ob prvem loadu modela s stekli mu nastavimo glasstype na null če ima steklo motiv
  if (defaults.motivType && !form.glassType) {
    onComponentChange({ glassType: null });
  }

  //ODPIRANJE VRAT IZ LINKA CHECK
  const shouldBeRight = form.doorOpeningRightSide && form.doorOpeningRightSide !== defaults.doorOpeningRightSide;
  const shouldBeLeft = form.doorOpeningLeftSide && form.doorOpeningLeftSide !== defaults.doorOpeningLeftSide;
  if (shouldBeRight || shouldBeLeft) {
    if (shouldBeLeft) {
      inoStage('odpiranjeDesno', false);
    } else {
      inoStage('odpiranjeDesno', true);
    }
  }

  inoStage('blackEditionState', form.blackEditionState);

  //TIP KONSTRUKCIJE IZ LINKA CHECK
  setCatalogue((cat) => ({ ...cat, tipiKonstrukcijeLevo: JSON.parse(sestav.getAvailableLevoDins()) }));
  setCatalogue((cat) => ({ ...cat, tipiKonstrukcijeDesno: JSON.parse(sestav.getAvailableDesnoDins()) }));
  setCatalogue((cat) => ({ ...cat, defaultFasadeList: JSON.parse(window.inoAPI.getFasada().getDefaultFasade()) }));

  //PROMISI ZA IZPIS IZ URL-ja
  var promisesCatalogue = [];

  //load STEKLA ORNAMENTNA catalogue (depends on modelId) ce imajo vrata steklo
  const stekloModelaOrn = JSON.parse(sestav.getSteklaUtil().getSteklaRequestDTO());
  const din = JSON.parse(sestav.getDin());

  if (stekloModelaOrn.steviloZasteklitev > 0) {
    onComponentChange({ doorHasGlass: true });
    loadCatalogue('steklaOrnamentna', stekloModelaOrn, 'steklaOrn');
  } else {
    onComponentChange({ doorHasGlass: false });
  }

  // ISKANJE MOTIVOV
  const stekloModelaMot = JSON.parse(sestav.getSteklaUtil().getMotivRequestDTO());
  if (sestav.getSteklaUtil().isKriloWithSteklo()) {
    loadCatalogue('steklaZasteklitev', stekloModelaMot, 'steklaMot');
  }

  //OBSVETLOBA LEVO & DESNO
  else if (din.hasLeftPanel) {
    loadCatalogue('steklaOsvetloba', stekloModelaMot, 'steklaMot');
  } else if (din.hasRightPanel) {
    loadCatalogue('steklaOsvetloba', stekloModelaMot, 'steklaMot');
  }

  //NADSVETLOBA
  else if (din.hasTopPanel) {
    loadCatalogue('steklaNadsvetloba', stekloModelaMot, 'steklaMot');
  }

  //GET BARVE
  const sestavBarve = JSON.parse(window.inoAPI.getSestav().getBarveUtil().getBarve());
  let filterLayer = JSON.parse(sestav.getBarveUtil().getFilteredProspekt());
  onComponentChange({ barveSestava: sestavBarve });

  // NAPOLNI BARVE CATALOGE
  function setColorCatalogue(element, cataTop, cata) {
    let topList = [];
    let list = [];
    defaults[element].forEach((col) => {
      promisesCatalogue.push(
        inoApiCall
          .barveTop({
            types: [col.type],
            filterProspekt: filterLayer
          })
          .then((res) => {
            if (res) {
              topList.push(res);
            }
          })
      );
      promisesCatalogue.push(
        inoApiCall
          .barveTip({
            types: [col.type],
            filterProspekt: filterLayer
          })
          .then((res) => {
            if (res) {
              list.push(res);
            }
          })
      );
      setCatalogue((cat) => ({ ...cat, [cataTop]: topList, [cata]: list }));
    });
  }

  //BARVE KRILO
  setColorCatalogue('colorWingOutside', 'kriloTopList', 'kriloList');

  //BARVE OKVIR
  setColorCatalogue('colorFrameOutside', 'okvirTopList', 'okvirList');

  //BARVE DEKOR
  defaults.colorDekorOutside.length > 0 && setColorCatalogue('colorDekorOutside', 'dekorjiTopList', 'dekorjiList');

  //BARVE APLIKACIJE
  defaults.colorAplikacijeOutside.length > 0 && setColorCatalogue('colorAplikacijeOutside', 'aplikacijeTopList', 'aplikacijeList');

  //BARVE OKVIRČKI
  defaults.colorOkvirckiOutside.length > 0 && setColorCatalogue('colorOkvirckiOutside', 'okvirckiTopList', 'okvirckiList');
  let barve;
  //VSE BARVE
  promises.push(
    inoApiCall.barveVse({}).then((res) => {
      if (res) {
        setCatalogue((cat) => ({ ...cat, vseBarveCatalog: res }));
        const vseBarveCatalog = res;
        barve = res;

        function setSelectedColor(elementId, element, isInside) {
          if (form[elementId] && form[elementId][0] !== '[]') {
            form[elementId].forEach((color, index) => {
              if (color && defaults[element][index] && color !== defaults[element][index].barva.composedId.replace(/[^a-zA-Z0-9]/g, '')) {
                let selectedColor = vseBarveCatalog.find((e) => e.composedId.replace(/[^a-zA-Z0-9]/g, '') === color);
                inoStageBarva(
                  {
                    type: defaults[element][index].type,
                    barva: selectedColor,
                    layer: defaults[element][index].layer
                  },
                  isInside
                );
              }
            });
          }
        }

        //NASTAVI BARVE IZ LINKA
        setSelectedColor('colorWingOutsideId', 'colorWingOutside', false);
        setSelectedColor('colorWingInsideId', 'colorWingInside', true);
        setSelectedColor('colorFrameOutsideId', 'colorFrameOutside', false);
        setSelectedColor('colorFrameInsideId', 'colorFrameInside', true);
        setSelectedColor('colorAplikacijeOutsideId', 'colorAplikacijeOutside', false);
        setSelectedColor('colorAplikacijeInsideId', 'colorAplikacijeInside', true);
        setSelectedColor('colorDekorOutsideId', 'colorDekorOutside', false);
        setSelectedColor('colorDekorInsideId', 'colorDekorInside', true);
        setSelectedColor('colorOkvirckiOutsideId', 'colorOkvirckiOutside', false);
        setSelectedColor('colorOkvirckiInsideId', 'colorOkvirckiInside', true);

        // if (form.outsideHandleColorsId) {
        //   let selectedCol = vseBarveCatalog.find(e => e.composedId.replace(/[^a-zA-Z0-9]/g, '') === form.outsideHandleColorsId[0]);
        //   // form.outsideHandleColorsId.forEach((color, index) => {
        //   //   console.log(color, index)
        //   //   let selectedColor = vseBarveCatalog.find(e => e.composedId.replace(/[^a-zA-Z0-9]/g, '') === color);
        //   //   // console.log('selected', selectedColor)
        //   //   // console.log(index+1)
        //   //   // console.log(('setZunanjaKljukaBarva' + (index+1)))
        //   // });
        //   inoPromise('setZunanjaKljukaBarva1', selectedCol)
        // }
      }
    })
  );

  Promise.allSettled(promises)
    .then(
      () =>
        new Promise(function (resolve, reject) {
          window.inoAPI.getSestav().setSestavFrontend(JSON.stringify(stage), (e, r) => {
            if (e) {
              resolve(e);
            } else {
              reject(r);
            }
          });
        })
    )
    .then(() => {
      // console.log("getSestavFrontend NEW VALUES: ",stage);
      svg = window.inoAPI.getDoorSketcher().getSVG();
      setDoorSvg(svg);

      let dovoljeniDodatki = JSON.parse(sestav.getDovoljeniDodatki());
      //KATALOG ZAKLEPANJA, PREVERI ČE IMA TRENUTNO DRŽALO ZAKLEPANJE
      const kljukaTip = JSON.parse(sestav.getZunanjaKljuka()).kljukaType;
      inoApiCall.zaklepanjaMehanizem({ tipKljuke: kljukaTip, dovoljeniDodatki: dovoljeniDodatki }).then((res) => {
        if (res) {
          let kljucavniceNaDrzalu = JSON.parse(sestav.getKljucavniceNaDrzalu());
          let vsaZaklepanja = res.concat(kljucavniceNaDrzalu);
          setCatalogue((cat) => ({ ...cat, zaklepanja: vsaZaklepanja }));
        }
      });

      const konstrukcija = JSON.parse(sestav.getDin());
      onComponentChange({
        dimensionHeightDefault: konstrukcija.defaultHeight,
        dimensionWidthDefault: konstrukcija.defaultWidth
      });
      // const sestavaBarv = JSON.parse(sestav.getBarveUtil().getBarve());

      if (form.outsideHandleColorsId) {
        // let selectedCol = barve && barve.find(e => e.composedId.replace(/[^a-zA-Z0-9]/g, '') === form.outsideHandleColorsId[0]);
        form.outsideHandleColorsId.forEach((color, index) => {
          let selectedColor = barve && barve.find((e) => e.composedId.replace(/[^a-zA-Z0-9]/g, '') === color);
          if (selectedColor) {
            let setter = 'setZunanjaKljukaBarva' + (index + 1);
            inoStage(setter, selectedColor);
            onComponentChange({ ['outsideHandleCol' + (index + 1)]: selectedColor });
          }
        });
      }

      let notranjeGarnitureShow = JSON.parse(window.inoAPI.getSestav().getZunanjaKljuka()).garnituraType;
      onComponentChange({ notranjeGarnitureShow: notranjeGarnitureShow });
      const sestavaBarv = JSON.parse(sestav.getBarveUtil().getBarve());
      onComponentChange({ colorsMain: sestavaBarv.aggregatedBarva });
      if (sestavaBarv.enableBasicEditor) {
        let mainColorsList = [];
        let mainColorsListTop = [];
        sestavaBarv.aggregatedBarva.forEach((color) => {
          const model = JSON.parse(sestav.getBarveUtil().getFilteredProspekt());
          inoApiCall.barveTop({ types: color.types, filterProspekt: model }).then((res) => {
            if (res) {
              mainColorsListTop.push(res);
              if (mainColorsList) {
                setCatalogue((cat) => ({
                  ...cat,
                  mainColorsListTop: mainColorsListTop,
                  mainColorsList: mainColorsList
                }));
              }
            }
          });
          inoApiCall.barveTip({ types: color.types, filterProspekt: model }).then((res) => {
            if (res) {
              mainColorsList.push(res);
              if (mainColorsListTop) {
                setCatalogue((cat) => ({
                  ...cat,
                  mainColorsListTop: mainColorsListTop,
                  mainColorsList: mainColorsList
                }));
              }
            }
          });
        });
      }
    });
};

export default sestavLoaded;
