import React, { useContext, useEffect, useState } from 'react';
import { Context } from 'shared-home/src/home/Context';
import { useTranslation } from 'react-i18next';

const BlackEditionButton = React.forwardRef((props, ref) => {
  const { form, onComponentChange } = useContext(Context);

  const { t } = useTranslation();

  const [blackEditionState, setBlackEditionState] = useState(false);

  useEffect(() => {
    //KO PRIDEJO DIMENZIJE IZ URL-JA SE NASTAVIJO V QUERY VISINA/SIRINA
    setBlackEditionState(form.blackEditionState);
  }, [form.blackEditionState]);

  const onClick = (e) => {
    const newState = !blackEditionState;
    setBlackEditionState(newState);
    onComponentChange({ blackEditionState: newState });
  };

  return (
    <div className="section select-dimensions" id="conf-step-num-4" step="4" ref={ref}>
      <div className="container">
        <h2 className="upper-case">{t('blackEditionTitle')}</h2>
        <button onClick={onClick} className={'square-round square-round--min-width ' + (blackEditionState ? 'square-round--active' : '')}>
          {!blackEditionState ? t('blackEditionAdd') : t('blackEditionRemove')}
        </button>
        <p className="notice">{t('blackEditionDesc')}</p>
      </div>
    </div>
  );
});

export default BlackEditionButton;
