import React, { useContext, useEffect, useState } from "react";
import { FastFilter } from "./FastFilter";
import { Context } from "./Context";

export function FastFilters(props) {
  const { onComponentChange, form } = useContext(Context);
  const initialState = { all: true, sale: false, top15: false, akcija: false };
  const [selected, setSelected] = useState(initialState);

  useEffect(() => {
    if (form.directFilters) {
      setSelected(form.directFilters);
    }
  }, [form.directFilters]);

  function onClick(type) {
    if (!selected[type]) {
      const newState = { all: false, sale: false, top15: false, akcija: false };
      newState[type] = true;
      const izberiModel = form.izberiModel.map((item) => {
        item.selected = false;
        return item;
      });

      onComponentChange({
        directFilters: newState,
      });
    }
  }

  return (
    <div className={"fast-filters"} style={{ display: "flex" }}>
      <FastFilter onClick={() => onClick("all")} selected={selected.all} image={"zimska.svg"} label={"headerFilterZimska"}/>
      {form.directFilterCacheAkcija && form.directFilterCacheAkcija.length > 0 &&
        <FastFilter onClick={() => onClick("akcija")} selected={selected.akcija} image={"TDM.svg"} label={"monthlyTopModel"}/>
      }

      {form.directFilterCacheSale && form.directFilterCacheSale.length > 0 &&
          <FastFilter onClick={() => onClick("sale")} selected={selected.sale} image={"akcija24.svg"} label={"headerFilterAkcija24"} />
      }

      {form.directFilterCacheTop && form.directFilterCacheTop.length > 0 &&
          <FastFilter onClick={() => onClick("top15")} selected={selected.top15} image={"top_modeli.svg"} label={"headerFilterTop"} />
      }
      {/*<FastFilter onClick={() => onClick("akcija")} selected={selected.akcija} image={"Black_edition_ikon.svg"} label={"headerFilterAkcija"}/>*/}
    </div>
  );
}
