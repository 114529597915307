export const translationNl = {
	modelType: "Modellen",
	chooseModel: "ALUMINIUM HUISDEUREN PROGRAMMA'S",
	glass: "Glas",
	modelShape: "Vorm van het glas",
	SearchApp: "Toepassingen op het model",
	chosenModel: "Geselecteerde model",
	configureDoors: "Configureer de deur",
	allDoors: "Alle",
	findModel: "Zoeken naar model",
	showResults: "Toon resultaat",
	filter: "Filter",
	save: "Opslaan en later verdergaan",
	sendInquiry: "Verzoek verzenden",
	headerModel: "SELECTEER HET MODEL",
	configurator: "Configurator",
	confDoorOpening: "Draairichting",
	confLeft: "Links",
	confRight: "Rechts",
	confConstructionType: "Type en uitvoering",
	confDimensions: "Afmetingen",
	confHeight: "Hoogte",
	confWidth: "Breedte",
	confHeightExample: "Bijv. 2100 mm",
	confWidthExample: "Bijv. 1050 mm",
	confExample: "Bijv.",
	confSkipDimensions: "Wanneer u momenteel geen informatie over de afmetingen hebt, kunt u deze stap overslaan.",
	confColors: "Kleuren",
	confColWhole: "ALLE ELEMENTEN KLEUREN",
	confColAdvanced: "Uitgebreide selectie van de kleuren",
	confCol1: "Kleur 1",
	confCol2: "Kleur 2",
	confMoreColors: "Meer keuren weergeven",
	confColorTop: "TOP 10 meest verkochte kleuren",
	confFindColor: "Kleur zoeken",
	confColorGroup: "KLEURGROEP",
	confColorShade: "Kleurnuance",
	confColMa: "Mat",
	confColSt: "Fijne structuur",
	confColShine: "Glanzend",
	confColLess: "Kleuren verbergen",
	confColReset: "Stel opnieuw in",
	confColWing: "Vleugel",
	confColOutside: "Buitenkant",
	confColInside: "Binnenkant",
	confColFrame: "Kozijn",
	confGlass: "Glazen",
	confMotGlass: "ONTWERP MOTIVATIE",
	confOrnGlass: "ORNAMENT GLAS",
	confHandle: "Handgrepen",
	confHandleOutside: "HANDGREEP BUITENKANT",
	confHandleInside: "Handgreep binnenkant",
	confHandleDesc: "Beschrijving",
	confHandleDim: "Afmetingen",
	confLock: "VERGRENDELEN",
	confLockTop: "Het meest populaire vergrendelingssysteem",
	confLockTypes: "Toon meer varianten",
	confLockAll: "Alle vergrendelingssystemen",
	confLockLess: "Verberg vergrendelingssystemen",
	confExtras: "Accessoires",
	confExtrasBattens: "Waterlijst",
	confSummary: "Configuratieoverzicht",
	confContinue: "Later verdergaan",
	confLook: "Toon",
	confBackground: "Achtergrond",
	confPicture: "Afbeelding",
	confChooseModel: "Selecteer een model",
	confPickedModel: "Geselecteerde model",
	confSwitchModel: "Model wijzigen",
	confHouseNo: "Huisnummer",
	confPeephole: "Digitale deurspion",
	confKnocker: "Deurklopper",
	confHouseInput: "Voer uw huisnummer in",
	confAdjust: "Aanpassen",
	confData: "Uw gegevens",
	InqName: "Achternaam",
	InqSurname: "Voornaam",
	InqAddress: "Straatnaam en huisnummer",
	InqPost: "Postcode",
	InqCity: "Plaatsnaam",
	InqEmail: "E-mailadres",
	InqEmDesc: "Wij hebben uw e-mailadres nodig om u een vrijblijvende offerte voor uw voordeur te sturen",
	InqPhone: "Telefoonnummer",
	InqPhoneDesc: "Wij hebben uw telefoonnummer alleen nodig voor het geval wij meer informatie nodig hebben om een offerte op te stellen",
	InqMessage: "Wilt u iets met ons delen?",
	InqDoor: "Betreft het een renovatie of nieuwbouw?",
	InqMark: "Specificeer",
	InqNew: "Nieuwbouw",
	InqChange: "Renovatie",
	InqContact: "Neem contact met ons op voor advies, verkoop en installatie",
	InqAgreement: "Ik ga ermee akkoord dat mijn contactgegevens worden doorgegeven aan de verkoper of de bevoegde partner voor advies en prijsopgave",
	InqNews: "Gratis en vrijblijvende toezending van brochures",
	InqFinish: "Verzoek verzenden",
	InqRequired: "Verplicht veld",
	InqSummary: "Configuratieoverzicht",
	InqInvalidEMail: "Ongeldig e-mailadres",
	InqConfSummary: "Lijst van de volledige configuratie",
	InqPDF: "Download als PDF",
	InqShare: "Koppeling delen",
	InqVisit: "Bezoek onze showroom",
	InqModel: "Model",
	InqColOutside: "Buitenkant",
	InqColInside: "Binnenkant",
	InqLeverInside: "Handgreep binnenkant",
	InqLeverOutside: "Handgreep buitenkant",
	InqHouseNo: "Huisnummer",
	InqDripBattens: "Waterlijst",
	InqKnocker: "Deurklopper",
	InqPeephole: "Digitale deurspion",
	close: "Sluiten",
	classicType: "Klassiek",
	modernType: "Modern",
	extravagantType: "Extravagant",
	simpleType: "Eenvoudig",
	topSeller: "Koploper",
	glassNone: "Zonder",
	glassSmall: "Klein",
	glassMedium: "Medium",
	glassBig: "Groot",
	shapeRectangle: "Rechthoekig",
	shapeSquare: "Vierkant",
	shapeCurves: "Krommen en bogen",
	shapeOther: "Andere vormen",
	searchMulticolor: "Meerkleurig",
	searchInox: "Roestvrij staal toepassing",
	searchUtori: "Reliëf",
	BGColor: "Selecteer de achtergrondkleur",
	SelectBGColor: "Selecteer de kleur",
	BGPictureTitle: "Afbeelding invoegen",
	BGPictureDesc: "Voeg een foto van uw woning in of kies een foto uit de volgende foto's",
	BGPicturestep1: "Stap 1",
	BGPictureTip1: "Upload of selecteer een foto",
	BGPicturestep2: "Stap 2",
	BGPictureTip2: "Positie van de deur",
	BGPicturestep3: "Stap 3",
	BGPictureTip3: "Doorgaan met de configuratie",
	BGPictureUpload: "Upload een afbeelding van de computer",
	BGPictureDesc2: "Of kies een foto",
	BGPictureChoose: "Gebruik afbeelding",
	confOpening: "Draairichting",
	confThankYouPage: "Bedankt voor uw aanvraag",
	noModelResults: "Geen resultaten gevonden",
	confAppOut: "Toepassing buitenkant",
	confAppIn: "Toepassing binnenkant",
	confDekorOut: "Decoratie buitenkant",
	confDekorIn: "Decoratie binnenkant",
	confWindowsOut: "Alunimium sierlijst buitenkant",
	confWindowsIn: "Alunimium sierlijst binnenkant",
	confHandleOutsideMainColor: "Geselecteerde kleur",
	CustomerInformation: "Uw gegevens",
	CustomerContact: "Contactgegevens",
	CustomerNameSurname: "Voor- en achternaam",
	CustomerAddress: "Straatnaam en huisnummer",
	CustomerPostCity: "Postcode en plaatsnaam",
	CustomerCountry: "Land",
	CustomerEmail: "E-mailadres",
	CustomerPhone: "Telefoonnummer",
	CustomerNote: "Speciaal verzoek",
	CustomerDoorSetup: "Heeft u de deur nodig voor een renovatie of nieuwbouw",
	TyPageDownload: "Converteer de configuratie naar PDF",
	TyPageShare: "Deel een link naar de configuratie",
	TyPageAppreciation: "Wij zijn verheugd dat u geïnteresseerd bent in de aankoop van een Inotherm voordeur. Wij zijn ervan overtuigd dat de deur perfect bij uw huis past. Als u ervoor kiest, sluit u zich aan bij de duizenden tevreden klanten over de hele wereld wier huizen al zijn uitgerust met de Inotherm-voordeur.",
	TyPageHeader: "Bedankt voor uw aanvraag",
	TyPageDescription1: "In de tabel hierboven ziet u alle details van uw huidige configuratie, waarvoor u binnen 24 uur een vrijblijvende offerte zult ontvangen.",
	TyPageDescription2: "Eén van onze medewerkers zal binnenkort contact met u opnemen om de volgende stappen te bespreken, bijvoorbeeld wijzigingen in de configuratie, afmetingen van de wandopening en de definitieve bestelling.",
	model: "Model",
	confHandleOutsideExtraColoring: "Extra kleuren",
	confHandleOutsideColor: "Kleur van de handgreep",
	confHandleOutsideAdditionalColor: "Kleur van de goot",
	confColorsOutside: "Kleur van de buitenkant",
	confColorsInside: "Kleur van de binnenkant",
	TyPageCopyRights: "Alle rechten voorbehouden 2023",
	logoLink: "https://www.inotherm.com/",
	InoWebsite: "Ga naar onze website",
	positionHouseNum: "Positie van het huisnummer op de deur",
	positionKnocker: "Positie van de deurklopper",
	positionPeephole: "Positie van het kijkgat in de deur",
	positionInfo: "Gebruik de cursos om het accessoire op de gewenste plaats te zetten",
	positionHorizontal: "HORIZONTAAL",
	positionVertical: "VERTICAAL",
	headerFilterVsi: "Alle modellen",
	headerFilterAkcija24: "Trend 24",
	headerFilterZimska: "Winteractie",
	headerFilterTop: "Top modellen",
	headerFilterAkcija: "Black Edition",
	openFilters: "Filters",
	clearFilters: "Alle filters verwijderen",
	useFilters: "Toon resultaat",
	bannerText: "KIES EEN DEURMODEL EN BEGIN MET HET ONTWERPEN VAN UW INGANG",
	miniCokel: "Laag basisprofiel",
	monthlyTopModel: "Deur van de maand",
	blackEditionTitle: "Black Edition",
	blackEditionDesc: "Verhoog het ontwerp van uw droomdeuren met de tijdloosheid van zwart.",
	blackEditionAdd: "TOEVOEGEN",
	blackEditionRemove: "VERWIJDEREN",
}
