export const translationCs = {
	modelType: "Modely",
	chooseModel: "Programy dveří",
	glass: "Sklo",
	modelShape: "Tvar skla",
	SearchApp: "Doplňky na modelu",
	chosenModel: "Vybraný model",
	configureDoors: "Vytvořte si dveře",
	allDoors: "Zobrazit výsledky",
	findModel: "Uložit a pokračovat později",
	showResults: "Zobrazit výsledky",
	filter: "Konfigurátor",
	save: "Uložit a pokračovat později",
	sendInquiry: "Typ a konstrukce",
	headerModel: "Výběr modelu",
	configurator: "Konfigurátor",
	confDoorOpening: "DIN - Směr",
	confLeft: "Levá",
	confRight: "Pravá",
	confConstructionType: "Typ a konstrukce",
	confDimensions: "ROZMĚRY",
	confHeight: "Výška",
	confWidth: "Šířka",
	confHeightExample: "např. 2100 mm",
	confWidthExample: "např. 1050 mm",
	confExample: "např.",
	confSkipDimensions: "Pokud ještě nemáte rozměry, můžete tento krok přeskočit",
	confColors: "Barvy",
	confColWhole: "BARVA VŠECH PRVKŮ",
	confColAdvanced: "Rozšířit výběr",
	confCol1: "Barva 1",
	confCol2: "Barva 2",
	confMoreColors: "Zobrazit další barvy",
	confColorTop: "TOP 10 nejprodávanějších barev",
	confFindColor: "Najít barvu",
	confColorGroup: "BAREVNÁ SKUPINA",
	confColorShade: "Odstín barvy",
	confColMa: "Matné",
	confColSt: "Struktura",
	confColShine: "Lesklý",
	confColLess: "Skrýt rozšířený výběr",
	confColReset: "Obnovit",
	confColWing: "Křídlo",
	confColOutside: "Venku",
	confColInside: "Uvnitř",
	confColFrame: "Osazovací rám dveře",
	confGlass: "Sklo",
	confMotGlass: "DEKORATIVNÍ SKLO",
	confOrnGlass: "ORNAMENTÁLNÍ SKLO",
	confHandle: "Rukojeti",
	confHandleOutside: "MADLO VENKOVNI",
	confHandleInside: "Vnitřní klika",
	confHandleDesc: "Popis",
	confHandleDim: "ROZMĚRY",
	confLock: "ZAMYKÁNÍ",
	confLockTop: "Nejoblíbenější uzamykací systémy",
	confLockTypes: "Zobrazit další uzamykací systémy",
	confLockAll: "Všechny uzamykací systémy",
	confLockLess: "Skrýt uzamykací systémy",
	confExtras: "Příslušenství",
	confExtrasBattens: "Okap",
	confSummary: "Přehled konfigurací",
	confContinue: "Pokračovat později",
	confLook: "Pohled",
	confBackground: "Pozadí",
	confPicture: "Foto",
	confChooseModel: "Výběr modelu dveří",
	confPickedModel: "Vybraný model",
	confSwitchModel: "Změna modelu",
	confHouseNo: "číslo domu",
	confPeephole: "Průzor na dveřích",
	confKnocker: "Klepadlo",
	confHouseInput: "Vložit číslo domu",
	confAdjust: "Proveďte úpravy",
	confData: "Vaše informace",
	InqName: "Jméno",
	InqSurname: "Příjmení",
	InqAddress: "Ulice, č.",
	InqPost: "PSČ",
	InqCity: "Město",
	InqEmail: "E-mail",
	InqEmDesc: "Potřebujeme vaši e-mailovou adresu, abychom vám mohli zaslat orientační cenovou nabídku na vaše vchodové dveře.",
	InqPhone: "Telefon",
	InqPhoneDesc: "Vaše telefonní číslo potřebujeme pouze pro případ, že bychom k přípravě nabídky potřebovali další informace.",
	InqMessage: "Chcete nám něco sdělit?",
	InqDoor: "Jedná se o rekonstrukci nebo novostavbu?",
	InqMark: "Označte odpovídajícím způsobem",
	InqNew: "Novostavba",
	InqChange: "Rekonstrukce",
	InqContact: "Předání kontaktu za účelem konzultace, prodeje a montáže",
	InqAgreement: "Souhlasím s tím, že pro poradenství nebo zasílání dokumentů budou kontaktní údaje poslány autorizovanému prodejci firmy INOTHERM!",
	InqNews: "Pošlete mi katalog.",
	InqFinish: "Odeslat poptávku",
	InqRequired: "Povinné pole",
	InqSummary: "Přehled konfigurace",
	InqInvalidEMail: "Neplatný email",
	InqConfSummary: "Seznam kompletní konfigurace",
	InqPDF: "Stáhněte si soubor PDF",
	InqShare: "Sdílet odkaz",
	InqVisit: "Navštivte náš showroom",
	InqModel: "Model",
	InqColOutside: "Venku",
	InqColInside: "Uvnitř",
	InqLeverInside: "Vnitřní klika",
	InqLeverOutside: "Madlo venkovní",
	InqHouseNo: "číslo domu",
	InqDripBattens: "Okap",
	InqKnocker: "Klepadlo",
	InqPeephole: "Průzor na dveřích",
	close: "Zavřít",
	classicType: "Klasické",
	modernType: "Moderní",
	extravagantType: "Extravagantní",
	simpleType: "Jednoduché",
	topSeller: "Nejprodávanějším",
	glassNone: "Bez",
	glassSmall: "Malé",
	glassMedium: "Střední",
	glassBig: "Velké",
	shapeRectangle: "Obdélníky",
	shapeSquare: "Čtverce",
	shapeCurves: "Křivky a oblouky",
	shapeOther: "Jiné tvary",
	searchMulticolor: "Dekor",
	searchInox: "Aplikace z ušlechtilé oceli",
	searchUtori: "Reliéf",
	BGColor: "Vyberte barvu pozadí",
	SelectBGColor: "Vyberte barvu",
	BGPictureTitle: "Vložte obrázek",
	BGPictureDesc: "Vložte obrázek svého domu nebo vyberte jednu z následujících fotografií",
	BGPicturestep1: "Krok 1",
	BGPictureTip1: "Nahrajte nebo vyberte obrázek",
	BGPicturestep2: "Krok 2",
	BGPictureTip2: "Umístěte dveře",
	BGPicturestep3: "Krok 3",
	BGPictureTip3: "Pokračujte v konfiguraci",
	BGPictureUpload: "Nahrajte obrázek z počítače",
	BGPictureDesc2: "nebo vyberte fotografii",
	BGPictureChoose: "Použijte obrázek",
	confOpening: "Otevření",
	confThankYouPage: "Děkujeme za Vaši poptávku!",
	noModelResults: "Nebyly nalezeny žádné výsledky",
	confAppOut: "Vnější aplikace",
	confAppIn: "Vnitřní aplikace",
	confDekorOut: "Vnější dekor",
	confDekorIn: "Vnitřní dekor",
	confWindowsOut: "Vnější hliníkový rám s ornamentem",
	confWindowsIn: "Vnitřní hliníkový rám s ornamentem",
	confHandleOutsideMainColor: "Vybraná barva",
	CustomerInformation: "Vaše údaje",
	CustomerContact: "Kontakt",
	CustomerNameSurname: "Jméno a příjmení",
	CustomerAddress: "Ulice a číslo domu",
	CustomerPostCity: "Poštovní směrovací číslo a místo",
	CustomerCountry: "Země",
	CustomerEmail: "E-mailová adresa",
	CustomerPhone: "Telefonní číslo",
	CustomerNote: "Poznámky k objednávce",
	CustomerDoorSetup: "Potřebujete dveře pro výměnu nebo novostavbu?",
	TyPageDownload: "Stáhněte si konfiguraci v dokumentu pdf",
	TyPageShare: "Sdílejte odkaz na konfiguraci",
	TyPageAppreciation: "Jsme potěšeni a poctěni, že máte zájem o koupi vchodových dveří Inotherm. Pevně věříme, že by se skvěle hodily k vašemu domu, a jejich výběrem byste se připojili k tisícům spokojených zákazníků po celém světě, jejichž domy již zdobí vchodové dveře Inotherm.",
	TyPageHeader: "Děkujeme vám za vaši poptávku!",
	TyPageDescription1: "V tabulce výše si můžete prohlédnout všechny podrobnosti o aktuální konfiguraci, na kterou vám také během následujících 24 hodin zašleme nezávaznou nabídku.",
	TyPageDescription2: "V nejbližší době Vás také bude kontaktovat jeden z našich obchodních specialistů, se kterým budete moci probrat další kroky, jako jsou změny konfigurace, rozměry otvorů ve stěnách a konečná objednávka.",
	model: "Modely",
	confHandleOutsideExtraColoring: "Další barvy",
	confHandleOutsideColor: "Barva rukojeti",
	confHandleOutsideAdditionalColor: "Barva zapuštěné části",
	confColorsOutside: "Vnější barvy",
	confColorsInside: "Vnitřní barvy",
	TyPageCopyRights: "All rights reserved 2023",
	logoLink: "https://www.inotherm.com/",
	InoWebsite: "To our website",
	positionHouseNum: "Umístění čísla domu na dveřích",
	positionKnocker: "Umístění klepadla na dveřích",
	positionPeephole: "Umístění kukátka na dveřích",
	positionInfo: "Pomocí níže uvedeného nástroje umístěte příslušenství do požadované polohy na dveřích.",
	positionHorizontal: "HORIZONTÁLNĚ",
	positionVertical: "VERTIKÁLNĚ",
	headerFilterVsi: "Všechny modely",
	headerFilterAkcija24: "Trend 24",
	headerFilterZimska: "Zimní akce",
	headerFilterTop: "Nejprodávanější produkty",
	headerFilterAkcija: "Černá edice",
	openFilters: "FILTRY",
	clearFilters: "Vymazat vše",
	useFilters: "Zobrazit výsledky",
	bannerText: "VYBERTE SI MODEL VCHODOVÝCH DVEŘÍ A ZAČNĚTE NAVRHOVAT SVŮJ VCHOD",
	miniCokel: "Nízký prahový profil",
	monthlyTopModel: "Dveře měsíce",
	blackEditionTitle: "Black Edition",
	blackEditionDesc: "Pozvedněte design svých vysněných dveří s nadčasovostí černé.",
	blackEditionAdd: "PŘIDAT",
	blackEditionRemove: "ODSTRANIT",
}
